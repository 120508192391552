var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('SeletorDeLojasDoUsuario',{staticStyle:{"margin-left":"20px"},attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","idDaLojaSalva":_vm.filtro.lojaId,"disabled":_vm.buscando,"rules":[_vm.obrigatorio]},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('RangeDatePicker',{attrs:{"loading":_vm.buscando,"disabled":_vm.buscando,"inicial":{
								label: 'Data inicial',
								clearable: true,
								rules: this.filtro.identificador ? undefined : [_vm.obrigatorio]
							},"final":{
								label:'Data final',
								clearable: true,
								rules: [_vm.dataFinalPermitida ].concat( (this.filtro.identificador ? [] : [_vm.obrigatorio])),
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"width":"110","color":"primary","loading":_vm.buscando},on:{"click":_vm.buscar}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"width":"110","loading":_vm.buscando},on:{"click":_vm.abrirFiltros}},[_vm._v(" Filtros ")])],1)],1)],1)],1)],1),_c('CamposFiltroDePedidos',{ref:"dialogoDeFiltros",attrs:{"eager":""},on:{"buscar":_vm.aplicarFiltros,"limpar":_vm.limparSelecaoDeFiltros},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}}),(!_vm.mostrarTabela)?_c('div',{staticClass:"text-center mt-3"},[_vm._v("Use os filtros para visualizar os pedidos")]):_vm._e(),_c('v-container',[(_vm.mostrarTabela)?_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"hideCreate":"","items":_vm.itensFormatados,"headers":_vm.headers,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] },"sort-by":"dataHora"},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('DialogoDeLogsDePedidos',{attrs:{"idDoPedido":item.pedido.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var dialog = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)})]}}],null,true)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }