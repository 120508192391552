




























import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase } from '@/usecases'
import { Prop, Vue, Component } from 'vue-property-decorator'
import { FindModalidadeDeVendaUseCase } from '@/usecases'
import { ModalidadeDeVenda } from '@/models'

@Component({})
export default class DialogoDeSelecaoDeModalidadeDeVenda extends Vue {
	@Prop({ type: String }) idVenda!: string 
	@Prop() lojaId!: string
	
	
	salvando = false
	mostra = false
	modalidades: ModalidadeDeVenda[] | [] = []
	modalidade: string | null = null
	
	
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()

	created() {
		this.carregar()
	}

	mostrar(){
		this.mostra = true
	}

	async confirmar(){
		setTimeout(() => this.salvar(), 1)
	}

	async carregar(): Promise<Array<ModalidadeDeVenda>> {
		const listaDeModalidades = await this.findModalidadeDeVendaUseCase.find(this.lojaId)
		this.modalidades = listaDeModalidades
		
		return listaDeModalidades
	}

	async salvar(){
		try {
			this.salvando = true
			if(!this.modalidades) return

			const modalidadeEncontrada = this.modalidades.find(itemModalidade => itemModalidade.modalidade === this.modalidade);
			
			if ( !modalidadeEncontrada ) return
			const modalidadeId = modalidadeEncontrada.id
			
			await this.editarUmaVendaUseCase.atualizarModalidade(modalidadeId, this.idVenda)

			this.$emit('atualizarModalidade', this.modalidade)
			AlertModule.setSuccess('Modalidade atualizada com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
		this.mostra = false
	}
}
