



































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { Venda, Cliente, FiltroDePedido } from '@/models'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { FindVendaUseCase } from '@/usecases'
import {obterDisplayClienteDaVenda } from '@/shareds/venda-shareds'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import { DataOptions } from 'vuetify'
import { obrigatorio } from '@/shareds/regras-de-form'
import DialogoDeLogsDePedidos from '../venda/DialogoDeLogsDePedidos.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import CamposFiltroDePedidos from '../venda/CamposFiltroDePedidos.vue'
import moment from 'moment'
import { nextTick } from '@/shareds/utils'
import SelecaoDePdv from '@/components/venda/SelecaoDePdv.vue'

const FILTRO_DE_LOGS = 'FILTRO_DE_LOGS'


@Component({
	components: {
		SeletorDeLojasDoUsuario,
		RangeDatePicker,
		SeletorDePessoa,
		DialogoDeLogsDePedidos,
		CamposFiltroDePedidos,
		SelecaoDePdv,
	},
})
export default class TelaDePedidos extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeFiltros!: CamposFiltroDePedidos
	

	filtro: FiltroDePedido = localStorage[FILTRO_DE_LOGS]
		? JSON.parse(localStorage[FILTRO_DE_LOGS])
		: {
			lojaId: null,
			cliente: null,
			situacoes: [],
			datas: [],
			identificador: null,
		}

	buscando = false
	mostrarTabela = false
	totalRegistros = 0

	vendas: Venda[] = []
	cliente: Cliente | null = null
	obrigatorio = obrigatorio
	findVendaUseCase = new FindVendaUseCase()

	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	obterDisplayClienteDaVenda = obterDisplayClienteDaVenda

	headers = [
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Loja', value: 'pontoDeVenda.loja.nomeFantasia' },
		{ text: 'PDV', value: 'pontoDeVenda.nome' },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Cliente', value:'displayCliente', sortable: false},
		{ text: 'Situação', value: 'displaySituacao', sortable: false },
		{ text: 'Ações', value : 'actions'},
	]

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaVenda(venda),
			displaySituacao: this.displaySituacao(venda),
			diplayDataHora: this.diplayDataHora(venda),
		}))
	}

	mounted() {
		if (this.filtro.lojaId !== null && this.filtro.datas.length === 2) {
			this.aplicarFiltros()
		}
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

	displaySituacao(venda: Venda) {
		const situacaoDoPedido = venda.pedido?.situacao
		return situacaoDoPedido
	}

	diplayDataHora(venda: Venda){
		if(!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	aplicarFiltros() {
		this.paginacao = {
			...this.paginacao,
			page: 1,
		}
	}

	dataFinalPermitida(val){
		return !this.filtro.datas[0] || !val
		|| moment(val, 'DD-MM-YYYY').isBefore(moment(this.filtro.datas[0]).add(30, 'd'))
		|| 'Selecione um intervalo menor que 30 dias'
	}

	async limparSelecaoDeFiltros() {
		try {
			this.buscando = true
			const page = await this.findVendaUseCase.list({
				page: this.paginacao.page -1,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
			})

			this.totalRegistros = page.totalElements

		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	async buscar() {
		await nextTick()
		if (!this.form.validate()) return
		try {
			this.buscando = true
			const pagina = await this.findVendaUseCase.find({
				dataHoraInicial: this.filtro.datas[0] && this.filtro.datas[1]
					? moment(this.filtro.datas[0]).toISOString(true)
					: undefined,
				dataHoraFinal: this.filtro.datas[0] && this.filtro.datas[1]
					? moment(`${this.filtro.datas[1]} 23:59:59 `).toISOString(true)
					:undefined,
				lojaId: this.filtro.lojaId || undefined,
				cnpjOuCpfCliente: this.filtro.cliente?.cnpjOuCpf,
				vendasComPedido: true,
				page: this.paginacao.page -1,
				size: this.paginacao.itemsPerPage,
				identificador: this.filtro.identificador || undefined,
				situacoesDoPedido: this.filtro.situacoes.length > 0
					? this.filtro.situacoes.join(',')
					: this.filtro.situacoes,
				sort: 'dataHora,desc',
			})
			this.vendas = pagina.content
			this.totalRegistros = pagina.totalElements
			this.mostrarTabela = true
			return pagina
		} catch (e) {
			AlertModule.setError(e)
		} finally{
			this.buscando = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_LOGS, stringified)
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar
}
