























































import { FormValeTroca } from '@/models/venda/ValeTroca';
import { obrigatorio, positivo } from '@/shareds/regras-de-form';
import {Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';

@Component({
	components: {

	},
})
export default class DialogoDeNumeroDeImpressoes extends Vue {
	@Prop({ default: 'Confirma?' }) titulo!: string
	@Prop() subtitulo?: string
	@Prop() opcaoPadrao?: 'Não' | 'Sim'
	@Prop({ type: Number, default: 1}) numeroPgna!: number
	@Prop({ default: '300' }) width!: string
	@Ref() botaoNao!: { $el: HTMLButtonElement }
	@Ref() botaoSim!: { $el: HTMLButtonElement }
	resolveDialogo: ((value: boolean) => void) | null = null
	obrigatorio = obrigatorio
	positivo = positivo
	formValeTroca: FormValeTroca[] = []

	mostrar: boolean | null = null

	async mostrando(): Promise<boolean> {
		this.mostrar = true
		return new Promise(resolve => this.resolveDialogo = resolve)
	}

	nega() {
		this.resolveDialogo && this.resolveDialogo(false)
		this.$emit('negar')
		this.mostrar = false
	}

	confirma() {
		this.resolveDialogo && this.resolveDialogo(true)
		this.$emit('confirmar', this.numeroPgna)
		this.mostrar = false
		this.numeroPgna = 1
	}

	
	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.resolveDialogo && this.resolveDialogo(false)
			return
		}
		setTimeout(() => {
			switch (this.opcaoPadrao) {
				case 'Não':
					return this.botaoNao.$el.focus()
				case 'Sim':
					return this.botaoSim.$el.focus()
			}
		}, 125)
	}
}
