













import Vue from 'vue'
import { Loja, PontoDeVenda } from '@/models'
import { FindLojaUseCase } from '@/usecases'
import { Component, PropSync, Watch } from 'vue-property-decorator'

@Component
export default class SelecaoDePdv extends Vue {
	@PropSync('pdv') pontoDeVenda!: PontoDeVenda | string
	@PropSync('loja') lojaId!: string
	busca = ''
	pdvs: PontoDeVenda[] = []
	findLojaUseCase = new FindLojaUseCase()

	get pdvsDaLoja() {
		if (!this.lojaId) return []
		return this.pdvs
			.filter(pdv => pdv.loja.id === this.lojaId)
			.sort((pdvA, pdvB) => pdvA.nome.localeCompare(pdvB.nome))
	}

	@Watch('lojaId')
	async onChangeLojaId() {
		if (!this.lojaId) return

		const loja: Loja = (await this.findLojaUseCase.findLojaById(this.lojaId))
		this.pdvs = loja.pdvs
		const pdvObj = this.pdvs.filter(pdv => pdv.id === this.pontoDeVenda)[0]
		if (!pdvObj) return

		if (pdvObj.loja.id !== this.lojaId) {
			this.pontoDeVenda = ''
		}
	}
}
