import { NotaDaVenda, Venda } from "@/models";
import { PatchVendaUseCase } from "./PatchVendaUseCase";
import {
	imprimirNotaFiscal as imprimirNotaFiscalPadrao,
} from '@/shareds/venda-shareds'

export default async ({
	imprimirNfe = false,
	venda,
	dialogoDeCpfDoCliente,
	confirmacaoDeCpfNaNota,
	isContingencia,
	imprimirNotaFiscal = imprimirNotaFiscalPadrao,
}: {
	imprimirNfe?: boolean
	venda: Venda
	dialogoDeCpfDoCliente: { mostrar: () => Promise<string> }
	confirmacaoDeCpfNaNota: { mostrar: () => Promise<boolean> }
	isContingencia: boolean
	imprimirNotaFiscal?: (idVenda: string, imprimirNfe: boolean, isContingencia: boolean) => Promise<any>
}) => {
	if (!venda) {
		throw new Error('Nenhuma venda informada')
	}
	if (venda.situacao === 'Cancelada') {
		throw new Error('Esta venda foi cancelada, não será possível fazer a emissão/reemissão da NFC-e.')
	}

	const patchVendaUseCase = new PatchVendaUseCase()
	const nota: NotaDaVenda | undefined = venda.notas[0]
	const statusDaNotaFiscal = nota?.cstat || ''

	if (!venda.cliente && !venda.cpfDoCliente && statusDaNotaFiscal !== '100') {
		venda.cpfDoCliente = await dialogoDeCpfDoCliente.mostrar()
		venda.exibirCpfNaNota = !!venda.cpfDoCliente

		const vendaParcial: Partial<Venda> = {
			id: venda.id,
			cpfDoCliente: venda.cpfDoCliente,
			exibirCpfNaNota: venda.exibirCpfNaNota,
		}
		await patchVendaUseCase.patchCpfNaNota(vendaParcial)
	} else if ((venda.cliente || venda.cpfDoCliente) && statusDaNotaFiscal !== '100') {
		venda.exibirCpfNaNota = await confirmacaoDeCpfNaNota.mostrar()
		const vendaParcial: Partial<Venda> = {
			id: venda.id,
			cpfDoCliente: venda.cpfDoCliente,
			exibirCpfNaNota: venda.exibirCpfNaNota,
		}
		await patchVendaUseCase.patchCpfNaNota(vendaParcial)
	}
	await imprimirNotaFiscal(venda.id, imprimirNfe, isContingencia)
} 