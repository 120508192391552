export default {
	'0': "Não comunicada",
	'1': "Nota reservada",
	'100': "Autorizado o uso da NF-e",
	'101': "Cancelamento de NF-e homologado",
	'102': "Inutilização de número homologado",
	'103': "Lote recebido com sucesso",
	'104': "Lote processado",
	'105': "Lote em processamento",
	'106': "Lote não localizado",
	'107': "Serviço em Operação",
	'108': "Serviço Paralisado Momentaneamente (curto prazo)",
	'109': "Serviço Paralisado sem Previsão",
	'110': "Uso Denegado",
	'111': "Consulta cadastro com uma ocorrência",
	'112': "Consulta cadastro com mais de uma ocorrência",
	'124': "EPEC Autorizado",
	'128': "Lote de Evento Processado",
	'135': "Evento registrado e vinculado a NF-e",
	'136': "Evento registrado, mas não vinculado a NF-e",
	'137': "Nenhum documento localizado para o Destinatário",
	'138': "Documento localizado para o Destinatário",
	'139': "Pedido de Download processado",
	'140': "Download disponibilizado",
	'142': "Ambiente de Contingência EPEC bloqueado para o Emitente",
	'150': "Autorizado o uso da NF-e, autorização fora de prazo",
	'151': "Cancelamento de NF-e homologado fora de prazo",
	'201': "Número máximo de numeração a inutilizar ultrapassou o limite",
	'202': "Falha no reconhecimento da autoria ou integridade do arquivo digital",
	'203': "Emissor não habilitado para emissão de NF-e",
	'204': "Duplicidade de NF-e [nRec:999999999999999]",
	'205': "NF-e está denegada na base de dados da SEFAZ [nRec:999999999999999]",
	'206': "NF-e já está inutilizada na Base de dados da SEFAZ",
	'207': "CNPJ do emitente inválido",
	'208': "CNPJ do destinatário inválido",
	'209': "IE do emitente inválida",
	'210': "IE do destinatário inválida",
	'211': "IE do substituto inválida",
	'212': "Data de emissão NF-e posterior a data de recebimento",
	'213': "CNPJ-Base do Emitente difere do CNPJ-Base do Certificado Digital",
	'214': "Tamanho da mensagem excedeu o limite estabelecido",
	'215': "Falha no schema XML",
	'216': "Chave de Acesso difere da cadastrada",
	'217': "NF-e não consta na base de dados da SEFAZ",
	'218': "NF-e já está cancelada na base de dados da SEFAZ [nRec:999999999999999]",
	'219': "Circulação da NF-e verificada",
	'220': "Prazo de Cancelamento superior ao previsto na Legislação",
	'221': "Confirmado o recebimento da NF-e pelo destinatário",
	'222': "Protocolo de Autorização de Uso difere do cadastrado",
	'223': "CNPJ do transmissor do lote difere do CNPJ do transmissor da consulta",
	'224': "A faixa inicial é maior que a faixa final",
	'225': "Falha no Schema XML do lote de NFe",
	'226': "Código da UF do Emitente diverge da UF autorizadora",
	'227': "Erro na Chave de Acesso - Campo Id – falta a literal NFe",
	'228': "Data de Emissão muito atrasada",
	'229': "IE do emitente não informada",
	'230': "IE do emitente não cadastrada",
	'231': "IE do emitente não vinculada ao CNPJ",
	'232': "IE do destinatário não informada",
	'233': "IE do destinatário não cadastrada",
	'234': "IE do destinatário não vinculada ao CNPJ",
	'235': "Inscrição SUFRAMA inválida",
	'236': "Chave de Acesso com dígito verificador inválido",
	'237': "CPF do destinatário inválido",
	'238': "Cabeçalho - Versão do arquivo XML superior a Versão vigente",
	'239': "Cabeçalho - Versão do arquivo XML não suportada",
	'240': "Cancelamento/Inutilização - Irregularidade Fiscal do Emitente",
	'241': "Um número da faixa já foi utilizado",
	'242': "Cabeçalho - Falha no Schema XML",
	'243': "XML Mal Formado",
	'244': "CNPJ do Certificado Digital difere do CNPJ da Matriz e do CNPJ do Emitente",
	'245': "CNPJ Emitente não cadastrado",
	'246': "CNPJ Destinatário não cadastrado",
	'247': "Sigla da UF do Emitente diverge da UF autorizadora",
	'248': "UF do Recibo diverge da UF autorizadora",
	'249': "UF da Chave de Acesso diverge da UF autorizadora",
	'250': "UF diverge da UF autorizadora",
	'251': "UF/Município destinatário não pertence a SUFRAMA",
	'252': "Ambiente informado diverge do Ambiente de recebimento",
	'253': "Digito Verificador da chave de acesso composta inválida",
	'254': "NF-e complementar não possui NF referenciada",
	'255': "NF-e complementar possui mais de uma NF referenciada",
	'256': "Uma NF-e da faixa já está inutilizada na Base de dados da SEFAZ",
	'257': "Solicitante não habilitado para emissão da NF-e",
	'258': "CNPJ da consulta inválido",
	'259': "CNPJ da consulta não cadastrado como contribuinte na UF",
	'260': "IE da consulta inválida",
	'261': "IE da consulta não cadastrada como contribuinte na UF",
	'262': "UF não fornece consulta por CPF",
	'263': "CPF da consulta inválido",
	'264': "CPF da consulta não cadastrado como contribuinte na UF",
	'265': "Sigla da UF da consulta difere da UF do Web Service",
	'266': "Série utilizada não permitida no Web Service",
	'267': "NF Complementar referencia uma NF-e inexistente",
	'268': "NF Complementar referencia outra NF-e Complementar",
	'269': "CNPJ Emitente da NF Complementar difere do CNPJ da NF Referenciada",
	'270': "Código Município do Fato Gerador: dígito inválido",
	'271': "Código Município do Fato Gerador: difere da UF do emitente",
	'272': "Código Município do Emitente: dígito inválido",
	'273': "Código Município do Emitente: difere da UF do emitente",
	'274': "Código Município do Destinatário: dígito inválido",
	'275': "Código Município do Destinatário: difere da UF do Destinatário",
	'276': "Código Município do Local de Retirada: dígito inválido",
	'277': "Código Município do Local de Retirada: difere da UF do Local de Retirada",
	'278': "Código Município do Local de Entrega: dígito inválido",
	'279': "Código Município do Local de Entrega: difere da UF do Local de Entrega",
	'280': "Certificado Transmissor inválido",
	'281': "Certificado Transmissor Data Validade",
	'282': "Certificado Transmissor sem CNPJ",
	'283': "Certificado Transmissor - erro Cadeia de Certificação",
	'284': "Certificado Transmissor revogado",
	'285': "Certificado Transmissor difere ICP-Brasil",
	'286': "Certificado Transmissor erro no acesso a LCR",
	'287': "Código Município do FG - ISSQN: dígito inválido",
	'288': "Código Município do FG - Transporte: dígito inválido",
	'289': "Código da UF informada diverge da UF solicitada",
	'290': "Certificado Assinatura inválido",
	'291': "Certificado Assinatura Data Validade",
	'292': "Certificado Assinatura sem CNPJ",
	'293': "Certificado Assinatura - erro Cadeia de Certificação",
	'294': "Certificado Assinatura revogado",
	'295': "Certificado Assinatura difere ICP-Brasil",
	'296': "Certificado Assinatura erro no acesso a LCR",
	'297': "Assinatura difere do calculado",
	'298': "Assinatura difere do padrão do Sistema",
	'299': "XML da área de cabeçalho com codificação diferente de UTF-8",
	'301': "Irregularidade fiscal do emitente",
	'302': "Irregularidade fiscal do destinatário",
	'303': "Destinatário não habilitado a operar na UF",
	'304': "Pedido de Cancelamento para NF-e com evento da Suframa",
	'321': "NF-e de devolução de mercadoria não possui documento fiscal referenciado",
	'323': "CNPJ autorizado para download inválido",
	'324': "CNPJ do destinatário já autorizado para download",
	'325': "CPF autorizado para download inválido",
	'326': "CPF do destinatário já autorizado para download",
	'327': "CFOP inválido para NF-e com finalidade de devolução de mercadoria",
	'328': "CFOP de devolução de mercadoria para NF-e que não tem finalidade de devolução de mercadoria",
	'329': "Número da DI /DSI inválido",
	'330': "Informar o Valor da AFRMM na importação por via marítima",
	'331': "Informar o CNPJ do adquirente ou do encomendante nesta forma de importação",
	'332': "CNPJ do adquirente ou do encomendante da importação inválido",
	'333': "Informar a UF do adquirente ou do encomendante nesta forma de importação",
	'334': "Número do processo de drawback não informado na importação",
	'335': "Número do processo de drawback na importação inválido",
	'336': "Informado o grupo de exportação no item para CFOP que não é de exportação",
	'337': "Não informado o grupo de exportação no item",
	'338': "Número do processo de drawback não informado na exportação",
	'339': "Número do processo de drawback na exportação inválido",
	'340': "Não informado o grupo de exportação indireta no item",
	'341': "Número do registro de exportação inválido",
	'342': "Chave de Acesso informada na Exportação Indireta com DV inválido",
	'343': "Modelo da NF-e informada na Exportação Indireta diferente de 55",
	'344': "Duplicidade de NF-e informada na Exportação Indireta (Chave de Acesso informada mais de uma vez)",
	'345': "Chave de Acesso informada na Exportação Indireta não consta como NF-e referenciada",
	'346': "Somatório das quantidades informadas na Exportação Indireta não corresponde a quantidade total do item",
	'347': "Descrição do Combustível diverge da descrição adotada pela ANP",
	'348': "NFC-e com grupo RECOPI",
	'349': "Número RECOPI não informado",
	'350': "Número RECOPI inválido",
	'351': "Valor do ICMS da Operação no CST=51 difere do produto BC e Alíquota",
	'352': "Valor do ICMS Diferido no CST=51 difere do produto Valor ICMS Operação e percentual diferimento",
	'353': "Valor do ICMS no CST=51 não corresponde a diferença do ICMS operação e ICMS diferido",
	'354': "Informado grupo de devolução de tributos para NF-e que não tem finalidade de devolução de mercadoria",
	'355': "Informar o local de saída do Pais no caso da exportação",
	'356': "Informar o local de saída do Pais somente no caso da exportação",
	'357': "Chave de Acesso do grupo de Exportação Indireta inexistente [nRef: xxx]",
	'358': "Chave de Acesso do grupo de Exportação Indireta cancelada ou denegada [nRef: xxx]",
	'359': "NF-e de venda a Órgão Público sem informar a Nota de Empenho",
	'360': "NF-e com Nota de Empenho inválida para a UF.",
	'361': "NF-e com Nota de Empenho inexistente na UF.",
	'362': "Venda de combustível sem informação do Transportador",
	'364': "Total do valor da dedução do ISS difere do somatório dos itens",
	'365': "Total de outras retenções difere do somatório dos itens",
	'366': "Total do desconto incondicionado ISS difere do somatório dos itens",
	'367': "Total do desconto condicionado ISS difere do somatório dos itens",
	'368': "Total de ISS retido difere do somatório dos itens",
	'369': "Não informado o grupo avulsa na emissão pelo Fisco",
	'370': "Nota Fiscal Avulsa com tipo de emissão inválido",
	'372': "Rejeição: Destinatário com identificação de estrangeiro com caracteres inválidos",
	'374': "Rejeição: CFOP incompatível com o grupo de tributação",
	'381': "Rejeição: Grupo de tributação ICMS90, informando dados do ICMS-ST",
	'382': "CFOP não permitido para o CST informado",
	'383': "Rejeição: Item com CSOSN indevido",
	'384': "NFCe	Rejeição: CSOSN não permitido para a UF",
	'385': "NFCe	Rejeição: Grupo de tributação ICMSSN900, informando dados do ICMS-ST",
	'386': "NFCe	Rejeição: CFOP não permitido para o CSOSN informado",
	'387': "NFe	Rejeição: Código de Enquadramento Legal do IPI inválido",
	'388': "NFe	Rejeição: Código de Situação Tributária do IPI incompatível com o Código de Enquadramento Legaldo IPI",
	'389': "NFe / NFCe	Rejeição: Código Município ISSQN inexistente",
	'390': "NFCe	Rejeição: Nota Fiscal com grupo de devolução de tributos",
	'391': "NFCe	Rejeição: Não informados os dados do cartão de crédito / débito nas Formas de Pagamento da Nota Fiscal",
	'392': "NFCe	Rejeição: Não informados os dados da operação de pagamento por cartão de crédito / débito",
	'393': "NFe	Rejeição: NF-e com o grupo de Informações Suplementares",
	'394': "NFCe	Rejeição: Nota Fiscal sem a informação do QR-Code",
	'395': "NFCe	Rejeição: Endereço do site da UF da Consulta via QR Code diverge do previsto",
	'396': "NFCe	Rejeição: Parâmetro do QR-Code inexistente",
	'397': "NFCe	Rejeição: Parâmetro do QR-Code divergente da Nota Fiscal",
	'398': "NFCe	Rejeição Parâmetro nVersao do QR-Code difere do previsto",
	'399': "NFCe	Rejeição: Parâmetro de Identificação do destinatário no QR-Code para Nota Fiscal sem identificação do destinatário",
	'401': "CPF do remetente inválido",
	'402': "XML da área de dados com codificação diferente de UTF-8",
	'403': "O grupo de informações da NF-e avulsa é de uso exclusivo do Fisco",
	'404': "Uso de prefixo de namespace não permitido",
	'405': "Código do país do emitente: dígito inválido",
	'406': "Código do país do destinatário: dígito inválido",
	'407': "O CPF só pode ser informado no campo emitente para a NF-e avulsa",
	'408': "Evento não disponível para Autor pessoa física",
	'409': "Campo cUF inexistente no elemento nfeCabecMsg do SOAP Header",
	'410': "UF informada no campo cUF não é atendida pelo Web Service",
	'411': "Campo versaoDados inexistente no elemento nfeCabecMsg do SOAP Header",
	'416': "Falha na descompactação da área de dados",
	'417': "Total do ICMS superior ao valor limite estabelecido",
	'418': "Total do ICMS ST superior ao valor limite estabelecido",
	'420': "Cancelamento para NF-e já cancelada",
	'450': "Modelo da NF-e diferente de 55",
	'451': "Processo de emissão informado inválido",
	'452': "Tipo Autorizador do Recibo diverge do Órgão Autorizador",
	'453': "Ano de inutilização não pode ser superior ao Ano atual",
	'454': "Ano de inutilização não pode ser inferior a 2006",
	'455': "Órgão Autor do evento diferente da UF da Chave de Acesso",
	'461': "Informado percentual de Gás Natural na mistura para produto diferente de GLP",
	'465': "Número de Controle da FCI inexistente",
	'466': "Evento com Tipo de Autor incompatível",
	'467': "Dados da NF-e divergentes do EPEC",
	'468': "NF-e com Tipo Emissão = 4, sem EPEC correspondente",
	'471': "Informado NCM=00 indevidamente",
	'476': "Código da UF diverge da UF da primeira NF-e do Lote",
	'477': "Código do órgão diverge do órgão do primeiro evento do Lote",
	'478': "Local da entrega não informado para faturamento direto de veículos novos",
	'484': "Chave de Acesso com tipo de emissão diferente de 4 (posição 35 da Chave de Acesso)",
	'485': "Duplicidade de numeração do EPEC (Modelo, CNPJ, Série e Número)",
	'489': "CNPJ informado inválido (DV ou zeros)",
	'490': "CPF informado inválido (DV ou zeros)",
	'491': "O tpEvento informado inválido",
	'492': "O verEvento informado inválido",
	'493': "Evento não atende o Schema XML específico",
	'494': "Chave de Acesso inexistente",
	'501': "Pedido de Cancelamento intempestivo (NF-e autorizada a mais de 7 dias)",
	'502': "Erro na Chave de Acesso - Campo Id não corresponde à concatenação dos campos correspondentes",
	'503': "Série utilizada fora da faixa permitida no SCAN (900-999)",
	'504': "Data de Entrada/Saída posterior ao permitido",
	'505': "Data de Entrada/Saída anterior ao permitido",
	'506': "Data de Saída menor que a Data de Emissão",
	'507': "O CNPJ do destinatário/remetente não deve ser informado em operação com o exterior",
	'508': "CNPJ do destinatário com conteúdo nulo só é válido em operação com exterior",
	'509': "Informado código de município diferente de “9999999” para operação com o exterior",
	'510': "Operação com Exterior e Código País destinatário é 1058 (Brasil) ou não informado",
	'511': "Não é de Operação com Exterior e Código País destinatário difere de 1058 (Brasil)",
	'512': "CNPJ do Local de Retirada inválido",
	'513': "Código Município do Local de Retirada deve ser 9999999 para UF retirada = EX",
	'514': "CNPJ do Local de Entrega inválido",
	'515': "Código Município do Local de Entrega deve ser 9999999 para UF entrega = EX",
	'516': "Falha no schema XML – inexiste a tag raiz esperada para a mensagem",
	'517': "Falha no schema XML – inexiste atributo versao na tag raiz da mensagem",
	'518': "CFOP de entrada para NF-e de saída",
	'519': "CFOP de saída para NF-e de entrada",
	'520': "CFOP de Operação com Exterior e UF destinatário difere de EX",
	'521': "CFOP de Operação Estadual e UF do emitente difere da UF do destinatário para destinatário contribuinte do ICMS.",
	'522': "CFOP de Operação Estadual e UF emitente difere da UF remetente para remetente contribuinte do ICMS.",
	'523': "CFOP não é de Operação Estadual e UF emitente igual a UF destinatário.",
	'524': "CFOP de Operação com Exterior e não informado NCM",
	'525': "CFOP de Importação e não informado dados da DI",
	'527': "Operação de Exportação com informação de ICMS incompatível",
	'528': "Valor do ICMS difere do produto BC e Alíquota",
	'529': "NCM de informação obrigatória para produto tributado pelo IPI",
	'530': "Operação com tributação de ISSQN sem informar a Inscrição Municipal",
	'531': "Total da BC ICMS difere do somatório dos itens",
	'532': "Total do ICMS difere do somatório dos itens",
	'533': "Total da BC ICMS-ST difere do somatório dos itens",
	'534': "Total do ICMS-ST difere do somatório dos itens",
	'535': "Total do Frete difere do somatório dos itens",
	'536': "Total do Seguro difere do somatório dos itens",
	'537': "Total do Desconto difere do somatório dos itens",
	'538': "Total do IPI difere do somatório dos itens",
	'539': "Duplicidade de NF-e com diferença na Chave de Acesso [chNFe: 99999999999999999999999999999999999999999999][nRec:999999999999999]",
	'540': "CPF do Local de Retirada inválido",
	'541': "CPF do Local de Entrega inválido",
	'542': "CNPJ do Transportador inválido",
	'543': "CPF do Transportador inválido",
	'544': "IE do Transportador inválida",
	'545': "Falha no schema XML – versão informada na versaoDados do SOAPHeader diverge da versão da mensagem",
	'546': "Erro na Chave de Acesso – Campo Id – falta a literal NFe",
	'547': "Dígito Verificador da Chave de Acesso da NF-e Referenciada inválido",
	'548': "CNPJ da NF referenciada inválido.",
	'549': "CNPJ da NF referenciada de produtor inválido.",
	'550': "CPF da NF referenciada de produtor inválido.",
	'551': "IE da NF referenciada de produtor inválido.",
	'552': "Dígito Verificador da Chave de Acesso do CT-e Referenciado inválido",
	'553': "Tipo autorizador do recibo diverge do Órgão Autorizador.",
	'554': "Série difere da faixa 0-899",
	'555': "Tipo autorizador do protocolo diverge do Órgão Autorizador.",
	'556': "Justificativa de entrada em contingência não deve ser informada para tipo de emissão normal.",
	'557': "A Justificativa de entrada em contingência deve ser informada.",
	'558': "Data de entrada em contingência posterior a data de recebimento.",
	'559': "UF do Transportador não informada",
	'560': "CNPJ base do emitente difere do CNPJ base da primeira NF-e do lote recebido",
	'561': "Mês de Emissão informado na Chave de Acesso difere do Mês de Emissão da NF-e",
	'562': "Código Numérico informado na Chave de Acesso difere do Código Numérico da NF-e [chNFe:99999999999999999999999999999999999999999999]",
	'563': "Já existe pedido de Inutilização com a mesma faixa de inutilização",
	'564': "Total do Produto / Serviço difere do somatório dos itens",
	'565': "Falha no schema XML – inexiste a tag raiz esperada para o lote de NF-e",
	'567': "Falha no schema XML – versão informada na versaoDados do SOAPHeader diverge da versão do lote de NF-e",
	'568': "Falha no schema XML – inexiste atributo versao na tag raiz do lote de NF-e",
	'569': "Data de entrada em contingência muito atrasada",
	'570': "Tipo de Emissão 3, 6 ou 7 só é válido nas contingências SCAN/SVC",
	'571': "O tpEmis informado diferente de 3 para contingência SCAN",
	'572': "Erro Atributo ID do evento não corresponde a concatenação dos campos (“ID” + tpEvento + chNFe + nSeqEvento)",
	'573': "Duplicidade de Evento",
	'574': "O autor do evento diverge do emissor da NF-e",
	'575': "O autor do evento diverge do destinatário da NF-e",
	'576': "O autor do evento não é um órgão autorizado a gerar o evento",
	'577': "A data do evento não pode ser menor que a data de emissão da NF-e",
	'578': "A data do evento não pode ser maior que a data do processamento",
	'579': "A data do evento não pode ser menor que a data de autorização para NF-e não emitida em contingência",
	'580': "O evento exige uma NF-e autorizada",
	'587': "Usar somente o namespace padrão da NF-e",
	'588': "Não é permitida a presença de caracteres de edição no início/fim da mensagem ou entre as tags da mensagem",
	'589': "Número do NSU informado superior ao maior NSU da base de dados da SEFAZ",
	'590': "Informado CST para emissor do Simples Nacional (CRT=1)",
	'591': "Informado CSOSN para emissor que não é do Simples Nacional (CRT diferente de 1)",
	'592': "A NF-e deve ter pelo menos um item de produto sujeito ao ICMS",
	'593': "CNPJ-Base consultado difere do CNPJ-Base do Certificado Digital",
	'594': "O número de sequencia do evento informado é maior que o permitido",
	'595': "Obrigatória a informação da justificativa do evento.",
	'596': "Evento apresentado fora do prazo: [prazo vigente]",
	'597': "CFOP de Importação e não informado dados de IPI",
	'598': "NF-e emitida em ambiente de homologação com Razão Social do destinatário diferente de NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL",
	'599': "CFOP de Importação e não informado dados de II",
	'601': "Total do II difere do somatório dos itens",
	'602': "Total do PIS difere do somatório dos itens sujeitos ao ICMS",
	'603': "Total do COFINS difere do somatório dos itens sujeitos ao ICMS",
	'604': "Total do vOutro difere do somatório dos itens",
	'605': "Total do vISS difere do somatório do vProd dos itens sujeitos ao ISSQN",
	'606': "Total do vBC do ISS difere do somatório dos itens",
	'607': "Total do ISS difere do somatório dos itens",
	'608': "Total do PIS difere do somatório dos itens sujeitos ao ISSQN",
	'609': "Total do COFINS difere do somatório dos itens sujeitos ao ISSQN",
	'610': "Total da NF difere do somatório dos Valores compõe o valor Total da NF.",
	'611': "cEAN inválido",
	'612': "cEANTrib inválido",
	'613': "Chave de Acesso difere da existente em BD",
	'614': "Chave de Acesso inválida (Código UF inválido)",
	'615': "Chave de Acesso inválida (Ano menor que 06 ou Ano maior que Ano corrente)",
	'616': "Chave de Acesso inválida (Mês menor que 1 ou Mês maior que 12)",
	'617': "Chave de Acesso inválida (CNPJ zerado ou dígito inválido)",
	'618': "Chave de Acesso inválida (modelo diferente de 55 e 65)",
	'619': "Chave de Acesso inválida (número NF = 0)",
	'620': "Chave de Acesso difere da existente em BD",
	'621': "CPF Emitente não cadastrado",
	'622': "IE emitente não vinculada ao CPF",
	'623': "CPF Destinatário não cadastrado",
	'624': "IE Destinatário não vinculada ao CPF",
	'625': "Inscrição SUFRAMA deve ser informada na venda com isenção para ZFM",
	'626': "CFOP de operação isenta para ZFM diferente do previsto",
	'627': "O valor do ICMS desonerado deve ser informado",
	'628': "Total da NF superior ao valor limite estabelecido pela SEFAZ [Limite]",
	'629': "Valor do Produto difere do produto Valor Unitário de Comercialização e Quantidade Comercial",
	'630': "Valor do Produto difere do produto Valor Unitário de Tributação e Quantidade Tributável",
	'631': "CNPJ-Base do Destinatário difere do CNPJ-Base do Certificado Digital",
	'632': "Solicitação fora de prazo, a NF-e não está mais disponível para download",
	'633': "NF-e indisponível para download devido a ausência de Manifestação do Destinatário",
	'634': "Destinatário da NF-e não tem o mesmo CNPJ raiz do solicitante do download",
	'635': "NF-e com mesmo número e série já transmitida e aguardando processamento",
	'650': 'Evento de "Ciência da Emissão" para NF-e Cancelada ou Denegada',
	'651': 'Evento de "Desconhecimento da Operação" para NF-e Cancelada ou Denegada',
	'653': "NF-e Cancelada, arquivo indisponível para download",
	'654': "NF-e Denegada, arquivo indisponível para download",
	'655': "Evento de Ciência da Emissão informado após a manifestação final do destinatário",
	'656': "Consumo Indevido",
	'657': "Código do Órgão diverge do órgão autorizador",
	'658': "UF do destinatário da Chave de Acesso diverge da UF autorizadora",
	'660': "CFOP de Combustível e não informado grupo de combustível da NF-e",
	'661': "NF-e já existente para o número do EPEC informado",
	'662': "Numeração do EPEC está inutilizada na Base de Dados da SEFAZ",
	'663': "Alíquota do ICMS com valor superior a 4 por cento na operação de saída interestadual com produtos importados",
	'678': "NF referenciada com UF diferente da NF-e complementar",
	'679': "Modelo da NF-e referenciada diferente de 55/65",
	'680': "Duplicidade de NF-e referenciada (Chave de Acesso referenciada mais de uma vez)",
	'681': "Duplicidade de NF Modelo 1 referenciada (CNPJ, Modelo, Série e Número)",
	'682': "Duplicidade de NF de Produtor referenciada (IE, Modelo, Série e Número)",
	'683': "Modelo do CT-e referenciado diferente de 57",
	'684': "Duplicidade de Cupom Fiscal referenciado (Modelo, Número de Ordem e COO)",
	'685': "Total do Valor Aproximado dos Tributos difere do somatório dos itens",
	'686': "NF Complementar referencia uma NF-e cancelada",
	'687': "NF Complementar referencia uma NF-e denegada",
	'688': "NF referenciada de Produtor com IE inexistente [nRef: xxx]",
	'689': "NF referenciada de Produtor com IE não vinculada ao CNPJ/CPF informado [nRef: xxx]",
	'690': "Pedido de Cancelamento para NF-e com CT-e",
	'691': "Chave de Acesso da NF-e diverge da Chave de Acesso do EPEC",
	'700': "Mensagem de Lote versão 3.xx. Enviar para o Web Service nfeAutorizacao",
	'701': "NF-e não pode utilizar a versão 3.00",
	'702': "NFC-e não é aceita pela UF do Emitente",
	'703': "Data-Hora de Emissão posterior ao horário de recebimento",
	'704': "NFC-e com Data-Hora de emissão atrasada",
	'705': "NFC-e com data de entrada/saída",
	'706': "NFC-e para operação de entrada",
	'707': "NFC-e para operação interestadual ou com o exterior",
	'708': "NFC-e não pode referenciar documento fiscal",
	'709': "NFC-e com formato de DANFE inválido",
	'710': "NF-e com formato de DANFE inválido",
	'711': "NF-e com contingência off-line",
	'712': "NFC-e com contingência off-line para a UF",
	'713': "Tipo de Emissão diferente de 6 ou 7 para contingência da SVC acessada",
	'714': "NFC-e com contingência DPEC inexistente",
	'715': "NFC-e com finalidade inválida",
	'716': "NFC-e em operação não destinada a consumidor final",
	'717': "NFC-e em operação não presencial",
	'718': "NFC-e não deve informar IE de Substituto Tributário",
	'719': "NF-e sem a identificação do destinatário",
	'720': "Na operação com Exterior deve ser informada tag idEstrangeiro",
	'721': "Operação interestadual deve informar CNPJ ou CPF.",
	'723': "Operação interna com idEstrangeiro informado deve ser para consumidor final",
	'724': "NF-e sem o nome do destinatário",
	'725': "NFC-e com CFOP inválido",
	'726': "NF-e sem a informação de endereço do destinatário",
	'727': "Operação com Exterior e UF diferente de EX",
	'728': "NF-e sem informação da IE do destinatário",
	'729': "NFC-e com informação da IE do destinatário",
	'730': "NFC-e com Inscrição Suframa",
	'731': "CFOP de operação com Exterior e idDest <> 3",
	'732': "CFOP de operação interestadual e idDest <> 2",
	'733': "CFOP de operação interna e idDest <> 1",
	'734': "NFC-e com Unidade de Comercialização inválida",
	'735': "NFC-e com Unidade de Tributação inválida",
	'736': "NFC-e com grupo de Veículos novos",
	'737': "NFC-e com grupo de Medicamentos",
	'738': "NFC-e com grupo de Armamentos",
	'739': "NFC-e com grupo de Combustível",
	'740': "NFC-e com CST 51-Diferimento",
	'741': "NFC-e com Partilha de ICMS entre UF",
	'742': "NFC-e com grupo do IPI",
	'743': "NFC-e com grupo do II",
	'745': "NF-e sem grupo do PIS",
	'746': "NFC-e com grupo do PIS-ST",
	'748': "NF-e sem grupo da COFINS",
	'749': "NFC-e com grupo da COFINS-ST",
	'750': "NFC-e com valor total superior ao permitido para destinatário não identificado (Código) [Limite]",
	'751': "NFC-e com valor total superior ao permitido para destinatário não identificado (Nome) [Limite]",
	'752': "NFC-e com valor total superior ao permitido para destinatário não identificado (Endereço) [Limite]",
	'753': "NFC-e com Frete",
	'754': "NFC-e com dados do Transportador",
	'755': "NFC-e com dados de Retenção do ICMS no Transporte",
	'756': "NFC-e com dados do veículo de Transporte",
	'757': "NFC-e com dados de Reboque do veículo de Transporte",
	'758': "NFC-e com dados do Vagão de Transporte",
	'759': "NFC-e com dados da Balsa de Transporte",
	'760': "NFC-e com dados de cobrança (Fatura, Duplicata)",
	'762': "NFC-e com dados de compras (Empenho, Pedido, Contrato)",
	'763': "NFC-e com dados de aquisição de Cana",
	'764': "Solicitada resposta síncrona para Lote com mais de uma NF-e (indSinc=1)",
	'765': "Lote só poderá conter NF-e ou NFC-e",
	'766': "NFC-e com CST 50-Suspensão",
	'767': "NFC-e com somatório dos pagamentos diferente do total da Nota Fiscal",
	'768': "NF-e não deve possuir o grupo de Formas de Pagamento",
	'769': "A critério da UF NFC-e deve possuir o grupo de Formas de Pagamento",
	'770': "NFC-e autorizada há mais de 24 horas.",
	'771': "Operação Interestadual e UF de destino com EX",
	'772': "Operação Interestadual e UF de destino igual à UF do emitente",
	'773': "Operação Interna e UF de destino difere da UF do emitente",
	'774': "NFC-e com indicador de item não participante do total",
	'775': "Modelo da NFC-e diferente de 65",
	'776': "Solicitada resposta síncrona para UF que não disponibiliza este atendimento (indSinc=1)",
	'777': "Obrigatória a informação do NCM completo",
	'778': "Informado NCM inexistente",
	'779': "NFC-e com NCM incompatível",
	'780': "Total da NFC-e superior ao valor limite estabelecido pela SEFAZ [Limite]",
	'781': "Emissor não habilitado para emissão da NFC-e",
	'782': "NFC-e não é autorizada pelo SCAN",
	'783': "NFC-e não é autorizada pela SVC",
	'784': "NFC-e não permite o evento de Carta de Correção",
	'785': "NFC-e com entrega a domicílio não permitida pela UF",
	'786': "NFC-e de entrega a domicílio sem dados do Transportador",
	'787': "NFC-e de entrega a domicílio sem a identificação do destinatário",
	'788': "NFC-e de entrega a domicílio sem o endereço do destinatário",
	'789': "NFC-e para destinatário contribuinte de ICMS",
	'790': "Operação com Exterior para destinatário Contribuinte de ICMS",
	'791': "NF-e com indicação de destinatário isento de IE, com a informação da IE do destinatário",
	'792': "Informada a IE do destinatário para operação com destinatário no Exterior",
	'793': "Informado Capítulo do NCM inexistente",
	'794': "NF-e com indicativo de NFC-e com entrega a domicílio",
	'795': "Total do ICMS desonerado difere do somatório dos itens",
	'796': "Empresa sem Chave de Segurança para o QR-Code",
	'999': "Erro não catalogado (informar a mensagem de erro capturado no tratamento da exceção)",
}
