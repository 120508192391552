




















































































import { obrigatorio } from "@/shareds/regras-de-form"
import type { FiltroDePedido} from '@/models'
import { Component, PropSync, Ref, Vue } from "vue-property-decorator"
import { nextTick } from '@/shareds/utils'
import RangeDatePicker from "@/components/ui/RangeDatePicker.vue"
import { limparFiltroDosCamposDePedidos } from "@/shareds/venda-shareds"
import SeletorDeCliente from "../clientes/SeletorDeCliente.vue"

@Component({
	components:{
		SeletorDeCliente,
		RangeDatePicker,
	},
})

export default class CamposFiltroDePedidos extends Vue {
	@PropSync('value',{
		default: () => ({
			datas: [],
			cliente: '',
			ambientes: '',
			situacao: [],
			lojaId: null,
		}),
	}) filtro!: FiltroDePedido
	@Ref() form!: HTMLFormElement

	dataVenda = []
	mostra = false
	buscando = false
	identificador = ''
	obrigatorio = obrigatorio

	mostrar(){
		this.mostra = true
	}

	ocultar(){
		this.mostra = false
	}

	async aplicarFiltro() {
		await nextTick()
		if(!this.form.validate()) return
		this.$emit('buscar', this.filtro)
	}

	limparFiltros(){
		this.$emit('limpar')
		this.$emit('input', limparFiltroDosCamposDePedidos())
	}

}
