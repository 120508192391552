


















import { Vue, Component, Ref } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
import { cnpjCpfInvalido } from '@/shareds/regras-de-form'

@Component({
	directives: {
		mask,
	},
})
export default class CampoDeCnpjOuCpf extends Vue {
	@Ref() campo!: HTMLInputElement
	cnpjCpfInvalido = cnpjCpfInvalido

	focus() {
		this.campo.focus()
	}
}

