






































































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { Cliente, Page, Pessoa } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import type { ClienteServiceAdapter, FornecedorServiceAdapter } from '@/usecases'
import type { TipoDeRecurso } from '@/models/Recurso'
import type { TipoDePessoa } from '@/models';
import { FindClienteUseCase, FindFornecedorUseCase } from '@/usecases'
import DialogoDeEdicaoDeCliente from './DialogoDeEdicaoDeCliente.vue'
import { Fragment } from 'vue-fragment'
import { formatarCnpjOuCpf, removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import { criarCliente, extrairCnpjOuCpfDaPessoa } from '@/shareds/cliente-shareds'
import { TipoDeOperacao } from '@/models/Recurso'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import { VAutocomplete, VCombobox } from 'vuetify/lib'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'
import { Inject } from 'inversify-props'

@Component({
	components: {
		DialogoDeEdicaoDeCliente,
		Fragment,
		VCombobox,
		VAutocomplete,
	},
})
export default class SeletorDePessoa extends Vue {
	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	@Inject('FornecedorServiceAdapter')
	private fornecedorService!: FornecedorServiceAdapter

	@PropSync('value') pessoa!: Pessoa | string | null
	@Prop({ type: Boolean, default: false }) hideCreate!: boolean
	@Prop({ type: String, default:  "Cliente"}) tipoDePessoa!: TipoDePessoa
	@Prop({ type: String }) recurso?: TipoDeRecurso
	@Prop({ type: Boolean, default: false }) podeDigitarCpfOuCnpj!: boolean
	@Prop({ type: Boolean, default: true }) podeEditarCliente!: boolean
	@Ref() campo!: HTMLInputElement
	@Ref() dialogo!: DialogoDeEdicaoDeCliente
	@PropSync('carregando') buscando!: boolean
	formatarCnpjOuCpf = formatarCnpjOuCpf
	busca = ''
	mostrar = false
	pessoas: Pessoa[] = []
	isCpfOrCnpj = false

	pessoasCarregadas = 0
	carregouTodosAsPessoas = false
	cancelToken: CancelTokenSource | null = null
	pagina: Page<Pessoa> | null = null
	carregando = false

	get findUseCase() {
		return this.tipoDePessoa == 'Cliente'
			? new FindClienteUseCase()
			: new FindFornecedorUseCase()
	}

	async created() {
		await this.carregar()
	}

	get obterComponente() {
		return this.podeDigitarCpfOuCnpj
			? 'VCombobox'
			: 'VAutocomplete'
	}

	get itens() {
		return this.busca  && !this.hideCreate
			? [...this.pessoas, 'novo']
			: this.pessoas
	}

	get tipoDePessoaLabel() {
		return this.tipoDePessoa == "Cliente"
			? "clientes"
			: "fornecedores"
	}

	get podeCriar() {
		if(this.hideCreate) return false
		return this.podeRealizarOperacao('criar')
	}

	async carregar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.buscando = true
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = { cancelToken: this.cancelToken.token }
			this.pagina = await this.findUseCase.find({
				busca: this.busca,
				page: this.busca ? -1 : (paginavel.page || 0),
				size: paginavel.size || 10,
				gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id),
			}, axiosConfig)

			this.pagina.content.forEach(pessoa => this.pessoas.push(pessoa))
			this.pessoasCarregadas += this.pessoas.length
			this.carregouTodosAsPessoas = this.pessoasCarregadas >= this.pagina.totalElements
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	formatarCasoSejaCnpjOuCpf() {
		if (typeof this.pessoa !==  'string') return
		const ehCpfOuCnpj  = /^\d{2}/.test(this.pessoa || '')
		if (!ehCpfOuCnpj) return
		this.$emit('input', formatarCnpjOuCpf(this.pessoa))
	}

	criarNovoCliente() {
		if (this.pessoa && typeof this.pessoa !== 'string') {
			this.dialogo.mostrar(this.pessoa as Cliente, false)
		} else {
			const pessoa = criarCliente()

			// ? Identifica tentativa de digitar um cpf ou cnpj
			if (this.busca && /^[\d|.|/|-]+$/.test(this.busca)) {
				pessoa.cnpjOuCpf = this.busca.replace(/\D/g, '')
			} else {
				pessoa.razaoSocialOuNome = this.busca || ''
			}
			this.dialogo.mostrar(pessoa, true)
		}
	}

	get obterIconeOuterAppend() {
		if (!this.podeCriar) return undefined

		return this.pessoa ? 'mdi-pencil': 'mdi-plus'
	}

	focus() {
		this.campo.focus()
	}

	filtrar(item: ItemAutocompletar, queryText: string) {
		if (item === 'novo') return true

		const query = queryText.replace(/\W+/g, '')
		const hasValue = val => (val ? val : '')
		const cnpjOuCpfTextLower = hasValue(item.cnpjOuCpf)
			.toString()
			.toLowerCase()

		const nomeDoClienteTextLower = hasValue(item.razaoSocialOuNome)
			.toString()
			.toLowerCase()
			.replace(/\W+/g, ''); 

		const queryLower = hasValue(query)
			.toString()
			.toLowerCase()

		return cnpjOuCpfTextLower.includes(queryLower)
			|| nomeDoClienteTextLower.includes(queryLower)
	}

	inserirPessoa(pessoa: Pessoa) {
		if (!pessoa) return

		const indice = this.pessoas.findIndex(
			pessoaExistente => pessoaExistente.id === pessoa.id,
		)

		indice === -1
			? this.pessoas.push(pessoa)
			: this.pessoas.splice(indice, 1, pessoa)

		this.$emit('input', pessoa)
	}

	selecionaItem(item: Pessoa | string) {
		this.$emit('input', item)
	}

	get recursoNormalizado() {
		return this.recurso || this.$route.meta?.recurso
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !this.recursoNormalizado || UserLoginStore.temAutorizacao(
			montarOperacaoDeRecurso(operacao, this.recursoNormalizado),
		)
	}

	async getPessoa(cpfOuCnpj: string): Promise<Pessoa> {
		switch(this.tipoDePessoa) {
			case 'Cliente':
				return await this.clienteService.get(cpfOuCnpj)
			case 'Fornecedor':
				return await this.fornecedorService.get(cpfOuCnpj)
			default:
				throw Error(`Tipo de pessoa ${this.tipoDePessoa} inesperado`)
		}
	}

	@Watch('tipoDePessoa')
	onChangeTipoDePessoa() {
		this.selecionaItem("")
		this.busca = ""
		this.pessoas = []
		this.pessoasCarregadas = 0 
		this.carregouTodosAsPessoas = false
		this.pagina = null
		this.carregar()
	}

	@Watch('pessoa', { immediate: true })
	async onChangePessoa(pessoaOuCnpjCpf: SeletorDePessoa['pessoa']) {
		if (typeof pessoaOuCnpjCpf === 'object') {
			return
		}

		const cpfOuCnpj = removerFormatacaoDeCnpjOuCpf(pessoaOuCnpjCpf)
		if (!isValidCPF(cpfOuCnpj) && !isValidCNPJ(cpfOuCnpj)) {
			return
		}

		if (extrairCnpjOuCpfDaPessoa(pessoaOuCnpjCpf) === cpfOuCnpj) {
			return
		}

		try {
			this.carregando = true
			const pessoa = await this.getPessoa(cpfOuCnpj);
			this.selecionaItem(pessoa)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	get cnpjOuCpfFormatado() {
		const cnpjOuCpf = extrairCnpjOuCpfDaPessoa(this.pessoa)
		return cnpjOuCpf ? formatarCnpjOuCpf(cnpjOuCpf) : ''
	}

	@Watch('busca', { deep: true })
	async observarBusca() {
		
		if( this.busca?.length == 11 || this.busca?.length == 14 ) {
			this.isCpfOrCnpj = true
			await this.carregar()
			const index =  this.pessoas.findIndex(
				pessoaExistente => pessoaExistente.cnpjOuCpf == this.busca,
			)
			this.$emit('pessoa', this.pessoas[index])
		} else {
			this.isCpfOrCnpj = false
		}
		await this.carregar()
		const index =  this.pessoas.findIndex(
			pessoaExistente => pessoaExistente.cnpjOuCpf == this.busca,
		)
		this.$emit('pessoa', this.pessoas[index])
	}
}

export type ItemAutocompletar = Cliente | 'novo'
