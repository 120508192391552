















































import { Loja, LojaSalvar } from '@/models';
import { criarLoja } from '@/shareds/loja-shareds';
import { obrigatorio } from '@/shareds/regras-de-form';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindClienteUseCase, FindLojaUseCase } from '@/usecases';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SeletorDeLojas from '../loja/SeletorDeLojas.vue';

@Component({
	components: {
		SeletorDeLojas,
	},
}) 
export default class DialogoDeVincularLojasComCliente extends Vue {
	@Prop() idCliente!: string

	mostra = false
	listaDeLoja: LojaSalvar[] = []

	findClienteUseCase = new FindClienteUseCase()
	findLojaUseCase = new FindLojaUseCase()

	obrigatorio = obrigatorio

	mostrar() {
		this.mostra = true
	}

	cancelar() {
		this.mostra = false
		this.listaDeLoja = []
	}

	async concluir() {
		const itensFormatados = this.listaDeLoja.map(lojaSalvar => lojaSalvar.loja)
		this.$emit("update:lojas", itensFormatados)
		this.mostra = false
		this.listaDeLoja = []
	}

	get lojaListagem() {
		return this.listaDeLoja
	}

	async adicionarParametro(novaLoja: LojaSalvar, indice: any) {
		let podeAdicionar: boolean | true = true
		if(!novaLoja && novaLoja == undefined) return 
		const existVinculo = await this.findClienteUseCase.existeVinculoDeClinteELoja(this.idCliente, novaLoja.loja.id)
		const clienteDaLista = this.listaDeLoja && this.listaDeLoja.length > 1 
			? this.listaDeLoja.filter(
				lojaCliente =>
					lojaCliente.loja.id === novaLoja.loja.id,
			) : []

		if (clienteDaLista.length > 1 || existVinculo) {
			AlertModule.setError(
				'Essa loja já está selecionada',
			)
			podeAdicionar = false
			this.listaDeLoja.splice(indice, 1)
			return
		}

		if (podeAdicionar) {
			novaLoja.loja.id != null
				? (this.listaDeLoja[indice] = novaLoja)
				: null
		}
	}

	adicionarNovaLoja() {
		this.listaDeLoja.push({loja: criarLoja as unknown as Loja})
	}
}
