






































import { Vue, Component, Ref  } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveClienteUseCase } from '@/usecases'
import { Endereco, EnderecoAdicional } from '@/models'
import CamposDeEndereco from '../ui/CamposDeEndereco.vue'
import { obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		CamposDeEndereco,
	},
})
export default class DialogoDeEdicaoEnderecoAdicional extends Vue {
	@Ref() form!: HTMLFormElement
	enderecoAdicional: EnderecoAdicional | null = null
	mostra = false
	salvando = false

	obrigatorio = obrigatorio

	saveClienteUseCase = new SaveClienteUseCase()

	mostrar(enderecoAdicionalAtual: EnderecoAdicional) {
		const enderecoAdicionalObj = new Object() as EnderecoAdicional

		const enderecoObj = new Object() as Endereco

		enderecoObj.id = enderecoAdicionalAtual.endereco.id
		enderecoObj.rua = enderecoAdicionalAtual.endereco.rua
		enderecoObj.numero = enderecoAdicionalAtual.endereco.numero
		enderecoObj.cep = enderecoAdicionalAtual.endereco.cep
		enderecoObj.bairro = enderecoAdicionalAtual.endereco.bairro
		enderecoObj.complemento = enderecoAdicionalAtual.endereco.complemento
		enderecoObj.cidade = enderecoAdicionalAtual.endereco.cidade
		enderecoObj.uf = enderecoAdicionalAtual.endereco.uf
		enderecoObj.codigoIBGE = enderecoAdicionalAtual.endereco.codigoIBGE
		enderecoObj.codigoPais = enderecoAdicionalAtual.endereco.codigoPais
		enderecoObj.nomePais = enderecoAdicionalAtual.endereco.nomePais

		enderecoAdicionalObj.id = enderecoAdicionalAtual.id
		enderecoAdicionalObj.endereco = enderecoObj
		enderecoAdicionalObj.tipo = enderecoAdicionalAtual.tipo
		enderecoAdicionalObj.isPadrao = enderecoAdicionalAtual.isPadrao

		this.enderecoAdicional = enderecoAdicionalObj
		this.mostra = true
	}

	esconder() {
		this.mostra = false
		this.enderecoAdicional = null
	}

	salvar() {
		if (!this.enderecoAdicional) return
		if (!this.form.validate()) return

		try {
			this.salvando = true

			this.$emit('atualizar', this.enderecoAdicional)
			
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.mostra = false
			this.salvando = false
			this.enderecoAdicional = null
		}
	}

	salvarComoPadrao() {
		if (!this.enderecoAdicional) return
		if (!this.form.validate()) return

		try {
			this.salvando = true

			this.$emit('atualizarComoPadrao', this.enderecoAdicional)
			
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.mostra = false
			this.salvando = false
			this.enderecoAdicional = null
		}
	}
}
