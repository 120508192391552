var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"440","scrollable":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[(_vm.cashback)?_c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-title',{staticClass:"primary white--text"},[_c('div',[_vm._v(" Detalhes de cashback ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"dark":"","icon":""},on:{"click":function () { return _vm.mostra = false; }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-list-item',[_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{staticClass:"mr-3",attrs:{"sm":"1"}},[_vm._v("Status:")]),_c('v-col',{class:_vm.statusColor(_vm.cashback.statusDeCashback)},[_vm._v(" "+_vm._s(_vm.cashback.statusDeCashback !== 'Concluido' ? _vm.cashback.statusDeCashback : 'Creditado')+" ")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cart")])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.cashback.identificadorDaVenda))])],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock-outline")])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.dateTimeToPtBrFormat(_vm.cashback.dataHora)))])],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total de cashback")]),_c('v-list-item-subtitle',[_vm._v("R$ "+_vm._s(_vm.formatarMoeda(_vm.cashback.valorTotalCreditado)))])],1)],1)],1),_c('v-divider'),_c('v-list',_vm._l((_vm.cashback.itensCashback),function(item){return _c('v-list-item',{key:item.id,attrs:{"two-line":item.produto.tipo === 'Variante',"to":_vm.temRecurso('ler', 'produtos')
					? {
						name: 'Produto',
						params: {
							id: item.produto.tipo === 'Variante'
								? item.produto
								: item.produto.id
						},
					}
					: undefined}},[_c('v-list-item-avatar',{attrs:{"color":"white"}},[_c('v-img',{staticStyle:{"color":"white"},attrs:{"src":"https://i.pinimg.com/originals/61/3c/ea/613ceaae3543acdf381af20da47612dc.png"}})],1),_c('v-list-item-content',[(item.produto.tipo === 'Variante')?_c('v-list-item-subtitle',{staticClass:"text-subtitle-2"},[_c('div',[_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" SKU "+_vm._s(item.produto.sku)+" ")])]),_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" "+_vm._s(_vm.displayVariantes(item.produto))+" ")])]):_vm._e(),_c('v-list-item-subtitle',[_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" EAN(s) "+_vm._s(item.produto.eans.join(', '))+" ")])]),_c('v-list-item-subtitle',[_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" EAN(s) "+_vm._s(item.produto.eans.join(', '))+" ")])]),_c('v-list-item-subtitle',{staticClass:"mt-3"},[_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" Recebimento: "+_vm._s(_vm.dateToPtBrFormat(item.dataParaRecebimento))+" ")])]),_c('v-list-item-subtitle',[_c('span',{staticStyle:{"margin-left":"32px"}},[_vm._v(" Expiração: "+_vm._s(item.dataParaExpirar ? _vm.dateToPtBrFormat(item.dataParaExpirar) : '- -')+" ")])])],1),_c('div',{staticClass:"text-right font-weight-bold"},[_c('div',[_vm._v("R$ "+_vm._s(_vm.formatarMoeda(item.valorCreditado)))])])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }