















































import { ItemDaVendaComReferencias, MotivoCancelamento } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import SeletorDeMotivosDeCancelamento from './SeletorDeMotivosDeCancelamento.vue'
import { FindLojaUseCase } from '@/usecases'

@Component({
	components: {
		SeletorDeMotivosDeCancelamento,
	},
})
export default class ConfirmacaoComMotivo extends Vue {
	@Prop({ default: 'Confirma?' }) titulo!: string
	@Prop() subtitulo?: string
	@Prop() opcaoPadrao?: 'Não' | 'Sim'
	@Prop({ default: '50' }) width!: string
	@Prop() loja?: string
	@Ref() botaoNao!: { $el: HTMLButtonElement }
	@Ref() botaoSim!: { $el: HTMLButtonElement }
	resolveDialogo: ((value: boolean) => void) | null = null
	mostra: boolean | null = null
	obrigatorio = obrigatorio
	motivo: MotivoCancelamento | null = null
	errors: string[] = []
	item: ItemDaVendaComReferencias | null = null

	findLojaUseCase = new FindLojaUseCase()
	exigeMotivoDeCancelamento = true

	async mostrar(item: ItemDaVendaComReferencias): Promise<boolean> {
		this.mostra = true
		this.item = item
		return new Promise(resolve => (this.resolveDialogo = resolve))
	}

	nega() {
		this.resolveDialogo && this.resolveDialogo(false)
		this.$emit('negar')
		this.mostra = false
	}

	async confirma() {
		await this.exigirMotivoDeCancelamento(this.loja)
		
		if (this.exigeMotivoDeCancelamento && !this.motivo) {
			AlertModule.setError('Necessário um motivo para cancelamento')
			return
		}
		this.resolveDialogo && this.resolveDialogo(true)
		this.$emit('confirmar', this.motivo, this.item)
		this.mostra = false
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.resolveDialogo && this.resolveDialogo(false)
			return
		}
		setTimeout(() => {
			switch (this.opcaoPadrao) {
				case 'Não':
					return this.botaoNao.$el.focus()
				case 'Sim':
					return this.botaoSim.$el.focus()
			}
		}, 125)
	}

	async exigirMotivoDeCancelamento(loja: string | undefined) {
		if (!loja) return
		const lojaDosPedidos = await this.findLojaUseCase.findLojaById(loja)

		return (this.exigeMotivoDeCancelamento =
			lojaDosPedidos.configuracaoDaLoja.exigeMotivoDeCancelamento)
	}
}
