var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.computedHeaders,"items":_vm.itensFormatados},scopedSlots:_vm._u([{key:"item.identificador",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{ 'text-decoration-line-through': item.situacao === 'Cancelada' }},on),[_vm._v(" "+_vm._s(item.identificador)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.tipoDeTransacao)+_vm._s(item.situacao === 'Cancelada' ? ' Cancelada' : '')+" ")])])]}},{key:"item.dataHora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeToPtBrFormat(item.dataHora))+" ")]}},{key:"item.displayTotalDaVenda",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',_vm._g({staticClass:"text-right",class:{ 'text-decoration-line-through': item.situacao === 'Cancelada' }},on),[_vm._v(" "+_vm._s(item.displayTotalDaVenda)+" ")]),_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":item.tipoDeTransacao === 'Transferência' ? 'yellow' : item.tipoDeTransacao === 'Venda' ? 'green' : 'red'}},on),[_vm._v(" "+_vm._s(item.tipoDeTransacao === 'Transferência' ? 'mdi-swap-vertical' : item.tipoNota === 'Saída' ? 'mdi-arrow-up' : item.tipoDeTransacao === 'Venda' ? 'mdi-arrow-up' : 'mdi-arrow-down')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tipoDeTransacaoFormatada(item))+_vm._s(item.situacao === 'Cancelada' ? ' Cancelada' : '')+" ")])])]}},{key:"item.displayNfSerie",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","disabled":!item.notas[0]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-end"},on),[(item.notas[0])?_c('v-icon',{attrs:{"color":item.notas[0].ambiente === 'Homologação' ?'grey': 'green accent-4'}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('div',{staticClass:"text-no-wrap pr-1",class:{ 'text-decoration-line-through': item.notas.length && item.notas[0].cstat === '101' }},[_vm._v(" "+_vm._s(item.displayNfSerie)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.notas[0] && item.notas[0].ambiente === 'Homologação' ?'Homologação': 'Produção'))])]),(item.notas.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-card',_vm._l((item.notas.slice(1)),function(nota,indice){return _c('v-list-item',{key:indice},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(nota.nnf)+"/"+_vm._s(nota.serie)+" - "+_vm._s(nota.modelo)+" ")]),(nota.cstat.length)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(nota.cstat)+" - "+_vm._s(_vm.mapErrosSefaz[nota.cstat])+" ")]):_vm._e()],1)],1)}),1)],1):_vm._e()],1)]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }