






















































import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import type { UsuarioBase, Venda, VendaComReferencias } from '@/models'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { EditarUmaVendaUseCase} from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		SeletorDeUsuario,	
	},
})
export default class DialogoDeInformarVendedorDaVenda extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({type: Object}) venda!: Venda
	@Prop({type: Object}) vendaComReferencias!: VendaComReferencias
	vendedor: UsuarioBase | null = null
	salvando: boolean | null = false
	mostra: boolean | null = false
	idPontoDeVenda: string | undefined =  ''
	editarVendaUseCase = new EditarUmaVendaUseCase()

	mostrar(idPdv: string | undefined) {
		//this.vendedor = this.venda.vendedor || null
		this.idPontoDeVenda = idPdv
		if (UserLoginStore.permiteRegraDeNegocio('pode-alterar-vendedor')) {
			this.mostra = true
		} else {
			this.mostra = false
		}
	}

	fechar(){
		this.mostra = false 
	}

	selecionarVendedor() {
		if(!this.vendedor) return
		this.$emit('input', this.vendedor)
	}
	
	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
		//this.vendedor = this.venda.vendedor
		}
	}

}
