
























import { formatDate, parseDate } from '@/shareds/date/date-utils'
import { Vue, Component, Ref, PropSync, Watch } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
	@PropSync('value', {
		default: new Date().toISOString().substr(0, 10),
	})
	date!: string | null
	@Ref() menu!: { save: (value: any) => void }

	dataFormatada: string | null = null
	activePicker: string | null = 'YEAR'

	mostrar = false

	parseDate = parseDate

	@Watch('date', { immediate: true })
	onChangeDate() {
		this.dataFormatada = formatDate(this.date)
	}
	
	@Watch('mostrar')
	onChangeMostrar(val){
		val && setTimeout(() => (this.activePicker = 'YEAR'))
	}

	handleInput(value: string) {
		if (value.length === 2 || value.length === 5) {
			value += '/'
		}
		this.dataFormatada = value
	}

	get dateValidationRule() {
		return (value: string) => {
			if (!value || value.length !== 10) {
				return 'A data deve estar no formato DD/MM/YYYY'
			}
			const parts = value.split('/')
			const day = parseInt(parts[0])
			const month = parseInt(parts[1])
			const year = parseInt(parts[2])
			const date = new Date(year, month - 1, day)
			if (
				date.getDate() !== day ||
        date.getMonth() !== month - 1 ||
        date.getFullYear() !== year
			) {
				return 'Data inválida'
			}
			return true
		}
	}
}
