




























import SeletorDeLojasDoUsuario from "@/components/loja/SeletorDeLojasDoUsuario.vue"
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import DataTableDeCrud from '../ui/DataTableDeCrud.vue'
import { nextTick } from '@/shareds/utils'
import axios, {CancelTokenSource } from 'axios'
import { FindClienteUseCase, SaveClienteUseCase } from "@/usecases"
import AlertModule from "@/store/vuex/aplicacao/AlertModule"
import { TipoDePagamentoBloqueadoCliente } from "@/models"
import Confirmacao from '../ui/Confirmacao.vue'
import DialogoDeEditarTiposDePagamentoDeCliente from '../cliente/DialogoDeEditarTiposDePagamentoDeCliente.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		DataTableDeCrud,
		DialogoDeEditarTiposDePagamentoDeCliente,
		Confirmacao,
	},
})
export default class ListagemDosBloqueiosDePagamentoPorCliente extends Vue {
	@Prop() idDoCliente!: string
	@Ref() confirmaAlteracoes!: Confirmacao
	@Ref() dialogoDeEditarTiposDePagamentoDeCliente!: DialogoDeEditarTiposDePagamentoDeCliente

	findClienteUseCase = new FindClienteUseCase()
	saveClienteUseCase = new SaveClienteUseCase()
	tiposDePagamentoDoCliente: TipoDePagamentoBloqueadoCliente[] = []
	buscando = false
	loading = false
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	
	totalRegistros = -1
	cancelToken: CancelTokenSource | null = null
	indice: number | null = null

	headers = [
		{ text: 'Tipo de Pagamento', value: 'nome' },
		{ text: 'Loja', value: 'nomeFantasiaLoja' },
	]

	@Watch('mostrar')
	created() {
		this.buscar()
	}

	@Watch('paginacao')
	async buscar() {
		if(!this.idDoCliente) return
		await nextTick()
		try {
			this.buscando = true
			const params = {
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken:axios.CancelToken.source().token,
			}
			const page = await this.findClienteUseCase.findTiposDePagamentosDoCliente(this.idDoCliente, params, axiosConfig)
			this.tiposDePagamentoDoCliente = page.content
			this.totalRegistros = page.totalElements

		} catch (error) {
			AlertModule.setError(error)
		}
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}

	async deleteBloqueioTipoDePagamento(){
		if(this.indice == null) return

		if(UserLoginStore.permiteRegraDeNegocio('pode-alterar-bloqueios-de-cliente')){
			this.loading = true
			const BloqueioDePagamentoSelecionado = this.tiposDePagamentoDoCliente[this.indice]
		
			try {
				await this.saveClienteUseCase.deleteBloqueioDePagamentoByClienteIdAndBloqueioId(BloqueioDePagamentoSelecionado.idBloqueioDePagamento, this.idDoCliente)
			} catch(e) {
				this.loading = false
				AlertModule.setError(e)
			} finally {
				this.loading = false
				this.tiposDePagamentoDoCliente.splice(this.indice, 1)
				AlertModule.setSuccess("Tipo de pagamento liberado com sucesso")
				this.indice = null
			}
		}else{
			AlertModule.setError("Usuário não possui liberação para alterar bloqueios de cliente.")
		}
	}

	abrirDialogoDeConfirmacao(indice: number){
		this.indice = indice
		this.confirmaAlteracoes.mostrar()
	}

	abrirDialogoDeEditarTiposDePagamentoCliente() {
		if(UserLoginStore.permiteRegraDeNegocio('pode-alterar-bloqueios-de-cliente')){
			this.dialogoDeEditarTiposDePagamentoDeCliente.mostrar()
		}
		else{
			AlertModule.setError("Usuário não possui liberação para alterar bloqueios de cliente.")
		}
	}

}
