



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FiltroDeVenda, ResumoDaVenda, SituacaoDeVenda } from '@/models';
import { DataOptions } from 'vuetify';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { CancelTokenSource } from 'axios';
import { FindVendaUseCase } from '@/usecases';
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import RodapePersonalizado from '../ui/data-tables/RodapePersonalizado.vue';
import { obterDisplayClienteDaVenda } from '@/shareds/venda-shareds';
import { formatarMoeda, removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores';
import { sortConverter } from '@/shareds/paginacao';
import moment from 'moment';
import { nextTick } from '@/shareds/utils';
import GridDeVendaResumido from '@/views/application/venda/GridDeVendaResumido.vue';

@Component({
	components: {
		DialogoDeDetalhesDaVenda,
		GridDeVendaResumido,
		RodapePersonalizado,
	},
})
export default class ListagemDasVendasPorCliente extends Vue {
	@Prop() cpfOuCnpjDoCliente!: string

	carregando = true
	loading = false
	mostrar = false
	buscando = false

	totalRegistros = -1

	cancelToken: CancelTokenSource | null = null
	vendas: ResumoDaVenda[] = []
	somaTotalDasVendas = 0
	buscaSituacaoDeVenda: SituacaoDeVenda[] = []

	findVendaUseCase = new FindVendaUseCase
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	filtro: FiltroDeVenda =  {
		busca: '',
		lojaId: '',
		datas: [],
		horas: [null, null],
		cliente: '',
		pdvId: '',
		numeroDaNota: '',
		serieFiscal: '',
		exibeVendasComErros: false,
		identificadorExterno: null,
		ambientes: ['Produção', 'Homologação'],
		identificador: '',
		vendasComOrcamento: false,
		vendasComPagamentoCancelado: false,		
		origem: null,
		vendasConsignadaDemonstracao: false,
		vendedorId: null,
	}

	@Watch('mostrar')
	created() {
		this.buscar()
	}

	@Watch('paginacao')
	async buscar() {
		if(!this.cpfOuCnpjDoCliente) return
		await nextTick()
		await this.buscarSomaTotalVendas();
		
		try {
			this.buscando = true
			const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00')
			const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59')
			const sorted = sortConverter(this.paginacao)
			
			const page = await this.findVendaUseCase.list({
				dataHoraInicial,
				dataHoraFinal,
				lojaId: this.filtro.lojaId || undefined,
				cliente: removerFormatacaoDeCnpjOuCpf((
					this.cpfOuCnpjDoCliente
				) || null,
				) || undefined,
				numeroNota: this.filtro.numeroDaNota || undefined,
				serie: this.filtro.serieFiscal || undefined,
				pdvId: this.filtro.pdvId || undefined,
				exibeVendasComErros: this.filtro.exibeVendasComErros,
				ambientes: this.filtro.ambientes.join(',') || undefined,
				identificador: this.filtro.identificador || undefined,
				vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
				origem: this.filtro.origem && this.filtro.origem.length > 0 ? this.filtro.origem.join(',') : undefined,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: !sorted.length ? 'dataHora,desc' : sorted,
			})
			this.buscaSituacaoDeVenda = Array.from(new Set(this.vendas.map(venda => venda.situacao)));
			this.vendas = page.content
			this.totalRegistros = page.totalElements

		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async buscarSomaTotalVendas() {
		if (!this.cpfOuCnpjDoCliente) return;
		await nextTick();
		this.buscando = true;
		try {
			await this.findVendaUseCase.list({
				lojaId: this.filtro.lojaId || undefined,
				cliente: removerFormatacaoDeCnpjOuCpf((
					this.cpfOuCnpjDoCliente
				) || null,
				) || undefined,
			})

			this.somaTotalDasVendas = this.vendas.reduce((total, venda) => {
				if (venda.situacao !== 'Cancelada') {
					return total + venda.total;
				} else {
					return total;
				}
			}, 0);
			const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00');
			const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59');
			const sorted = sortConverter(this.paginacao);

			const page = await this.findVendaUseCase.list({
				dataHoraInicial,
				dataHoraFinal,
				lojaId: this.filtro.lojaId || undefined,
				cliente: removerFormatacaoDeCnpjOuCpf((this.cpfOuCnpjDoCliente) || null) || undefined,
				numeroNota: this.filtro.numeroDaNota || undefined,
				serie: this.filtro.serieFiscal || undefined,
				pdvId: this.filtro.pdvId || undefined,
				exibeVendasComErros: this.filtro.exibeVendasComErros,
				ambientes: this.filtro.ambientes.join(',') || undefined,
				identificador: this.filtro.identificador || undefined,
				vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
				origem: this.filtro.origem && this.filtro.origem.length > 0 ? this.filtro.origem.join(',') : undefined,
				page: 0,
				size: 50,
				sort: !sorted.length ? 'dataHora,desc' : sorted,
			});
			this.vendas = page.content;

			if (this.filtro.origem && this.filtro.origem.length > 0) {
				this.somaTotalDasVendas = this.vendas.reduce((total, venda) => {			
					if (venda.situacao !== 'Cancelada') {
						return total + venda.total;
					} else {					
						return total;	
					}
				}, 0);
			} else {
				this.somaTotalDasVendas = this.vendas.reduce((total, venda) => {                    
					if (venda.situacao !== 'Cancelada') {
						return total + venda.total;
					} else {
						return total;
					}
				}, 0);
			}

		} catch (error: any) {
			this.buscando = false;
			AlertModule.setError(error);
		}
		
	}


	filtroAtivo(venda: ResumoDaVenda): boolean {
		if (this.filtro.origem && this.filtro.origem.length > 0) {
			const vendaOrigemString = venda.vendaOrigem ? venda.vendaOrigem.toString() : '';
			return this.filtro.origem.includes(vendaOrigemString);
		}
		return true;
	}


	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayNfSerie: this.displayNfSerie(venda),
			displayCliente: obterDisplayClienteDaVenda(venda),
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			numeroDaNota: venda.notas[0] ? venda.notas[0].nnf : null,
			serieFiscal: venda.notas[0] ? venda.notas[0].serie : null,
		}))
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	displayNfSerie (venda: ResumoDaVenda ){
		const nota = venda.notas[0]
		return nota
			? nota.nnf + ' / ' + nota.serie
			: ''
	}

	displayTotalDaVenda(venda: ResumoDaVenda){
		return formatarMoeda(venda.total)
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}
}
