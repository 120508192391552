





















































import type { VendaComReferencias } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase } from '@/usecases'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { isValorNumerico } from '@/shareds/regras-de-form'

@Component
export default class DialogoDeEdicaoDoTicket extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() venda!: VendaComReferencias	
	@Prop() lojaId!: string	
	
	salvando = false
	mostra = false	
	numeroTicket: string | null = null	
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	isValorNumerico = isValorNumerico	

	mostrar(){		
		this.mostra = true
		this.numeroTicket = this.venda.numeroTicket		
	}	

	esconder() {
		this.mostra = false
	}	

	async confirmar(){
		setTimeout(() => this.salvar(), 1)
	}
	
	async excluir(){
		setTimeout(() => this.handleDelete(), 1)
	}

	async salvar(){
		try {			
			if(!this.numeroTicket) return
			
			const numericPattern = /^\d*$/
			if (!numericPattern.test(this.numeroTicket)) {
				AlertModule.setError('Insira apenas números')
				return
			}
			
			await this.editarUmaVendaUseCase.atualizarNumeroDoTicket(this.numeroTicket, this.venda.id)

			this.$emit('atualizarTicket', this.numeroTicket)
			AlertModule.setSuccess('Número do ticket atualizado com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
		this.mostra = false
	}

	
	async handleDelete() {
		try {
			this.salvando = true							
			
			await this.editarUmaVendaUseCase.removerNumeroTicket(this.venda.id)

			this.$emit('atualizarTicket', null)
			AlertModule.setSuccess('Número do ticket excluido com sucesso!')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
		this.mostra = false
	}
}
