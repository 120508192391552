


































import { LogsDePedidos } from '@/models/venda/LogsDePedidos'
import { formatarDataHora } from '@/shareds/formatadores'
import { FindLogsDePedidosUseCase } from '@/usecases/venda/findLogsDePedidosUseCase'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'

@Component
export default class DialogoDeLogsDePedidos extends Vue {
	@Prop() idDoPedido!: string

	findVendaUseCase = new FindLogsDePedidosUseCase()
	mostra: boolean | null = null
	logsDePedidos: LogsDePedidos[] = []
	headers = [
		{text: 'Data', value: 'displayDataHora', sortable: false },
		{text: 'Usuário', value: 'displayDeUsuario', sortable: false },
		{text: 'Status Anterior', value: 'situacaoAnterior', sortable: false },
		{text: 'Status Atual', value: 'situacaoAtual', sortable: false },
		{text: 'Observação', value: 'displayObservacao', sortable: false },
		{text: 'Observação do Sistema', value: 'displayObservacaoDoSistema', sortable: false },
	]

	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	totalRegistros = 0

	created(){
		this.buscarLogs()
	}

	async buscarLogs(){
		const logs = await this.findVendaUseCase.findLogsByIdDoPedido(this.idDoPedido, {
			page: this.paginacao.page -1,
			size: this.paginacao.itemsPerPage,
		})
		this.logsDePedidos = logs.content
		this.totalRegistros = logs.totalElements
	}

	mostrar(){
		this.mostra = true
	}

	get itensFormatados(){
		return this.logsDePedidos.map( log => ({
			...log,
			displayObservacaoDoSistema: this.displayObservacaoDoSistema(log),
			displayObservacao: this.displayObservacao(log),
			displayDeUsuario: this.displayDeUsuario(log),
			displayDataHora: this.displayDataHora(log),
		}))
	}

	displayObservacaoDoSistema(log: LogsDePedidos){
		const observacoaDoSistema = log.observacaoDoSistema 
		return  observacoaDoSistema
	}

	displayObservacao(log: LogsDePedidos){
		const observacao = log.observacao
		return observacao
	}

	displayDeUsuario(log: LogsDePedidos){
		const usuario = log.usuario.nome
		return usuario
	}

	displayDataHora(log: LogsDePedidos){
		const dataHora = formatarDataHora(log.createdAt)
		return dataHora
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarLogs

}
