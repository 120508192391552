






















































import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import type { Venda } from '@/models'
import { UsuarioBase } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { EditarUmaVendaUseCase } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		SeletorDeUsuario,	
	},
})
export default class DialogoDeInformarRepresentanteComercial extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({type: Object}) venda!: Venda
	representante: UsuarioBase | null = null
	salvando: boolean | null = false
	mostra: boolean | null = false

	editarVendaUseCase = new EditarUmaVendaUseCase()

	mostrar() {
		this.representante = this.venda.representanteComercial || null
		if (UserLoginStore.permiteRegraDeNegocio('pode-alterar-vendedor')) {
			this.mostra = true
		
		} else {
			this.mostra = false
		}
	}

	fechar(){
		this.mostra = false 
	}

	async informarRepresentante(){
		try { 
			this.salvando = true
			this.venda = {
				...this.venda,
				representanteComercial: this.representante,
			}
			this.$emit('input', this.venda)

			if (this.representante) {
				await this.editarVendaUseCase.patchRepresentante(this.venda.id, this.representante)
			}

			this.mostra = false
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.salvando = false
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			this.representante = this.venda.representanteComercial
		}
	}

}
