







































import { Vue, Component, Ref } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Qriously from 'vue-qriously'
import { uploadFilesToS3 } from '@/shareds/s3/files'
import { AnexoUseCase } from '@/usecases'
import { TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

Vue.use(Qriously, { name:"qriously"})

@Component({})
export default class DialogoDeAnexoDeArquivos extends Vue {
	@Ref() uploadForm!: HTMLFormElement
	@Ref() fileInput!: {
		$el: HTMLInputElement
	}

	qrData = ''
	mostra = false
	carregando = false
	tipoDoProcesso: TipoDeProcesso | null = null
	arquivos: File[] = []
	anexoId: string | null = null
	identificadorDoProcesso = ''

	anexoUseCase = new AnexoUseCase()
	
	AlertModule = AlertModule

	async mostrar(identificador: string, tipoDoProcesso: TipoDeProcesso) {
		const usuarioId = UserLoginStore.usuario?.id
		this.anexoId = await this.anexoUseCase.findAnexoIdByIdentificador(identificador)
		this.identificadorDoProcesso = identificador
		this.tipoDoProcesso = tipoDoProcesso
		this.qrData = `${this.extractDomain(window.location.href)}/anexo/${this.tipoDoProcesso}/${this.identificadorDoProcesso}/${usuarioId}`
		this.mostra = true
		setTimeout(() => {
			this.uploadForm.resetValidation()
		})
	}

	extractDomain(url) {
		let domain = url.replace(/^https?:\/\//, '')
		domain = domain.split('/')[0]
		domain = domain.split(':')[0]

		return domain;
	}

	async upload() {
		if (!this.uploadForm.validate()) return
		if(!this.tipoDoProcesso) {
			AlertModule.setError("Ocorreu um erro interno, contate o suporte")
			return
		}
		if (!this.arquivos || this.arquivos.length === 0) {
			AlertModule.setError('Selecione pelo menos um arquivo para anexar')
			return
		}

		try {
			this.carregando = true

			const pattern = /[~`!@#$%^&*()áÁàÀçÇõÕãÃôÔêÊ\-+={}[\]|\\;:'",<>/?]+/g;
			const espaco = /\s+/g;

			if (this.arquivos.length > 0 && (pattern.test(this.arquivos[0].name) || espaco.test(this.arquivos[0].name))) {
				const anexo = this.arquivos[0];
				let url = anexo.name.replace(espaco, '_');
				url = url.replace(pattern, '');
				const anexoFormatado = new File([anexo], url, {
					type: anexo.type,
					lastModified: anexo.lastModified,
				});
				this.arquivos[0] = anexoFormatado;
			}
			
			const informacoesDosArquivos = await uploadFilesToS3(
				this.arquivos, `anexo/${this.tipoDoProcesso}/${this.identificadorDoProcesso}`,
			)

			const arquivosComExtensao = informacoesDosArquivos.map(
				({ config }) => config.url?.split("?")[0] || "",
			).filter(url => url && url.length > 0)

			await this.anexoUseCase.createOuUpdate({
				id: this.anexoId,
				urlDosArquivos: arquivosComExtensao,
				idDoProcesso: this.identificadorDoProcesso,
				tipoDeProcesso: this.tipoDoProcesso,
			})

			AlertModule.setSuccess('Arquivo(s) anexado(s) com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.arquivos = []
			this.carregando = false
			this.mostra = false
		}
	}
}


