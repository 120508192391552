






































































































































import type { Venda } from '@/models'
import { Fornecedor, Pageable } from '@/models'
import { Vue, Component, Ref, Watch, PropSync } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EdicaoDeUmaVenda, EditarUmaVendaUseCase, FindFornecedorUseCase } from '@/usecases'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import { listasDeModalidadesDeFrete } from '@/shareds/venda-shareds'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'

@Component({
	components: {
		Confirmacao,
		CampoDecimal,
	},
})
export default class DialogoDeEdicaoDeTransporteDaVenda extends Vue {
	@PropSync('value') vendaDeReferencia!: Venda
	@Ref() campoDeModalidade!: HTMLInputElement
	@Ref() form!: HTMLFormElement

	mostra: boolean | null = null
	buscandoFornecedores: boolean | null = null
	salvando = false

	obrigatorio = obrigatorio
	listasDeModalidadesDeFrete = listasDeModalidadesDeFrete
	listaDeFornecedores: Fornecedor[] = []
	teste = 0

	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	findFornecedorUseCase = new FindFornecedorUseCase()

	venda: EdicaoDeUmaVenda | null = null
	pageable: Pageable = {
		page: 0,
		size: 10,
	}
	busca: string | null = null
	bucouTodasAsTransportadoras = false
	

	@Watch('mostra')
	onChangeMostra() {
		this.listaDeFornecedores = []
		this.pageable.page = 0
		this.bucouTodasAsTransportadoras = false
		this.buscarTransportadoras()	

		if(this.venda?.informacoesDeTransporte?.pesoBruto !== 0 || this.venda?.informacoesDeTransporte.pesoLiquido !== 0) {
			this.venda = {
				informacoesDeTransporte: {
					modalidadeDeFrete: this.vendaDeReferencia.informacoesDeTransporte.modalidadeDeFrete,
					volume: this.vendaDeReferencia.informacoesDeTransporte.volume,
					especie: this.vendaDeReferencia.informacoesDeTransporte.especie,
					pesoLiquido: this.vendaDeReferencia.informacoesDeTransporte.pesoLiquido,
					pesoBruto: this.vendaDeReferencia.informacoesDeTransporte.pesoBruto,
					fornecedor: this.vendaDeReferencia.informacoesDeTransporte.fornecedor,
					valorFrete: this.vendaDeReferencia.informacoesDeTransporte.valorFrete,
				},
				produtosVerificados: false,
			}
		}

		this.busca = this.venda.informacoesDeTransporte?.fornecedor?.razaoSocialOuNome || null
		window.requestAnimationFrame(() => {
			this.campoDeModalidade.focus()
		})
	}

	@Watch('venda.informacoesDeTransporte.modalidadeDeFrete')
	onChangeModalidadeDeFrete() {
		this.listaDeFornecedores = []
		this.pageable.page = 0

		if (this.venda && this.venda.informacoesDeTransporte && this.venda.informacoesDeTransporte.modalidadeDeFrete === 'Sem Frete') {
			this.venda.informacoesDeTransporte.fornecedor = null
			this.venda.informacoesDeTransporte.pesoBruto = null
			this.venda.informacoesDeTransporte.pesoLiquido = null
			this.venda.informacoesDeTransporte.especie = null
			this.venda.informacoesDeTransporte.volume = null
			this.venda.informacoesDeTransporte.valorFrete = null
		}
		this.buscarTransportadoras()
	}

	async buscarTransportadoras() {
		try {
			this.buscandoFornecedores = true

			const params = {
				tipoFornecedor: 'Transportadora',
				page: this.pageable.page ? this.pageable.page : 0,
				size: this.pageable.size,
				sort: this.pageable.sort,
				busca: this.busca,
			}

			const fornecedoresPage = await this.findFornecedorUseCase.find(params)

			if (!fornecedoresPage.last) {
				this.pageable.page = !this.pageable.page
					? 1
					: (this.pageable.page += 1)
				this.listaDeFornecedores.push(...fornecedoresPage.content)
				this.bucouTodasAsTransportadoras = false
			} else {
				this.listaDeFornecedores.push(...fornecedoresPage.content)
				this.bucouTodasAsTransportadoras = true
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscandoFornecedores = false
		}
	}

	esconder() {
		this.mostra = false
	}

	async atualizarVenda() {
		if (!this.venda) return
		if (!this.form.validate()) return
		try {
			this.salvando = true
			const vendaAtualizada = await this.editarUmaVendaUseCase.executar(
				this.vendaDeReferencia.id,
				this.venda,
			)

			this.$emit('input', vendaAtualizada)
			this.mostra = false
			AlertModule.setSuccess('Venda salva com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
