











































































































import { Cashback } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import { displayVariantes } from '@/shareds/produto-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindCashbackUseCase } from '@/usecases/cliente/FindCashbackUseCase'
import {Component, Prop, Vue} from 'vue-property-decorator' 
import { statusColor } from './ListagemDeCashback.vue'
import { dateTimeToPtBrFormat, dateToPtBrFormat } from '@/shareds/date/date-utils'

@Component
export default class DialogoDeDetalhesDoCashback extends Vue {
	@Prop({ type: String }) cashbackId!: string

	findCashbackUseCase = new FindCashbackUseCase()

	cashback: Cashback | null = null

	mostra: boolean | null = null
	carregando = false

	statusColor = statusColor
	formatarMoeda = formatarMoeda
	displayVariantes = displayVariantes
	dateToPtBrFormat = dateToPtBrFormat
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	created() {
		this.buscaCashback()
	}

	async buscaCashback() {
		if(!this.cashbackId) return
		try {
			this.carregando = true
			this.cashback = await this.findCashbackUseCase.findById(this.cashbackId)
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}
}
