




























import { Vue, Component, Prop, Watch, Ref} from 'vue-property-decorator'
import { FindProfissaoUseCase } from '../../usecases/cliente/FindProfissaoUseCase'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '../../store/vuex/aplicacao/AlertModule'
import { Profissao, Page, Pageable } from '../../models'

@Component
export default class SeletorDeProfissao extends Vue {	
	@Prop({ type: String}) profissaoSalva!: string | null
	@Prop({ type: Array, default: () => [] }) readonly items!: Profissao[]	
	@Ref() campo!: HTMLInputElement	
	@Prop({type: Array, default: () => []}) excludes!: string[]

	profissoes: Profissao[] = []	
	busca = ''
	pagina: Page<Profissao> | null = null
	cancelToken: CancelTokenSource | null = null
	findProfissaoUseCase = new FindProfissaoUseCase()
	carregando = true
	profissoesCarregados = 0
	carregouTodasProfissoes = false

	pageable: Pageable = {
		page: 0,
		size: 5,
		sort: [],
	}
	
	async created() {
		this.carregando = true
		try {			
			if (this.profissaoSalva) {
				this.busca = this.profissaoSalva				
				const profissaoObj: Profissao = { codigo: '', descricao: this.profissaoSalva }
				this.profissoes.push(profissaoObj)
				await this.buscar()
			} else {
				await this.buscar()
			}
		} finally {
			this.carregando = false
		}
	}	

	async buscar() {
		this.carregando = true
		if (this.cancelToken) this.cancelToken.cancel()
		try {			
			this.cancelToken = axios.CancelToken.source()
			
			this.pagina = await this.findProfissaoUseCase.findProfissoes({
				page: this.busca ? this.pageable.page || -1 : this.pageable.page || 0,
				size: this.pageable.size,
				busca: this.busca || undefined,
				axiosConfig: {
					cancelToken: this.cancelToken.token,
				},
			});

			if (this.pagina && this.pagina.content) {
				this.pagina?.content.forEach(profissao => {
					this.profissoes.push(profissao)					
				})
			} else {
				console.error('Response structure is not as expected:', this.pagina);
			}
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}

		return this.profissoes
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1
		await this.buscar()
	}

	@Watch('busca', { deep: true, immediate: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (!buscaAnterior || busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.profissoes = []
		this.profissoesCarregados = 0
		this.buscar()
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.profissoes.length) {
			return 'Nenhum resultado encontrado...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}	
}

