





































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class RodapePersonalizado extends Vue {
	@Prop({ type: Object }) paginacao!: {
		page: number
		itemsPerPage: number
		itemsLength: number
	}
	@Prop({ type: Number }) total!: number
	@Prop({ type: Boolean, default: false }) loading!: boolean
	@Prop({ type: Array, default: () => [5, 10, 25, 50] }) itensPerPageOptions!: number[]

	navegar(pages): void {
		this.$emit('navegar', pages)
	}

	@Watch("paginacao.itemsPerPage")
	onChangeItemsPerPage() {
		this.$emit('navegar', 0)
	}
}
