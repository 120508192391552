

















































































































































































































































































































































































import { Endereco, EnderecoAdicional, FormCliente, UsuarioBase } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindClienteUseCase, SaveClienteUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import { cnpjCpfValido, obrigatorio, maiorOuIgualAZero } from '@/shareds/regras-de-form'
import CamposDeEndereco from '@/components/ui/CamposDeEndereco.vue'
import CampoDeCnpjOuCpf from '@/components/ui/CampoDeCnpjOuCpf.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import DatePickerAniversario from '@/components/ui/DatePickerAniversario.vue'
import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { TipoDeOperacao } from '@/models/Recurso'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import ListagemDeVouchers from "@/components/cliente/ListagemDeVouchers.vue";
import { nextTick } from '@/shareds/utils'
import SeletorDeUsuario from '../usuario/SeletorDeUsuario.vue'
import ListagemDeLojaPorCliente from '../loja/ListagemDeLojaPorCliente.vue'
import ListagemDasVendasPorCliente from './ListagemDasVendasPorCliente.vue'
import ListagemDosGruposEconomicosPorCliente from './ListagemDosGruposEconomicosPorCliente.vue'
import SeletorDeProfissao from './SeletorDeProfissao.vue'
import ListagemDosBloqueiosDePagamentoPorCliente from './ListagemDosBloqueiosDePagamentoPorCliente.vue'
import DataTableDeCrud from '../ui/DataTableDeCrud.vue'
import DialogoDeEdicaoEnderecoAdicional from './DialogoDeEdicaoEnderecoAdicional.vue'
import { v4 as uuidv4 } from 'uuid'
import AppStore from '@/store/vuex/aplicacao/AppStore'

type AbaDoCliente = 'Principal' | 'Planos' | 'Contatos' | 'Vouchers' | 'Crediario' | 'Vincular' | 'Lojas' | 'Vendas' | 'Grupos Econômicos' | 'Bloqueios de Pagamento'

@Component({
	components: {
		CampoDeTelefone,
		CamposDeEndereco,
		CampoDeCnpjOuCpf,
		SeletorDeTipoDeCliente,
		DatePicker,
		DatePickerAniversario,
		ListagemDeVouchers,
		SeletorDeUsuario,
		ListagemDeLojaPorCliente,
		ListagemDasVendasPorCliente,
		ListagemDosGruposEconomicosPorCliente,
		SeletorDeProfissao,
		ListagemDosBloqueiosDePagamentoPorCliente,
		DataTableDeCrud,
		DialogoDeEdicaoEnderecoAdicional,
	},
})
export default class DialogoDeEdicaoDeCliente extends Vue {
	@Prop({ type: String, default: 'Gênero' }) placeholderGenero!: string;			
	@Ref() campoDeNome!: HTMLInputElement
	@Ref() form!: HTMLFormElement
	@Prop() atualizaPorCampoDeCpf!: boolean
	@Ref() dialogoDeEnderecoAdicional!: DialogoDeEdicaoEnderecoAdicional
	
	salvando = false
	cliente: FormCliente | null = null
	mostra = false
	valid = false
	saveClienteUseCase = new SaveClienteUseCase()
	findClienteUseCase = new FindClienteUseCase()
	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero
	buscandoCnpjOuCpf = false
	autoCompletaEndereco = true
	errors: string[] = []
	tabs: AbaDoCliente[] = ['Principal', 'Planos', 'Contatos', 'Vouchers', 'Crediario', 'Lojas', 'Vendas', 'Grupos Econômicos', 'Bloqueios de Pagamento']
	tab: null | number = null
	criandoCliente = false
	genero = ['MASCULINO', 'FEMININO', 'OUTROS']	
	vendedor: UsuarioBase | null = null
	preencherFormulario = false
	carregandoEnderecos = false
	nomeDeTratamentoModificado = false

	get abasPermitidas() {
		return this.tabs
			.filter(aba => {
				if (aba !== 'Planos') return true
				return UserLoginStore.permiteRegraDeNegocio('pode-alterar-planos-do-cliente')
			})
			.filter(aba => {
				if (aba !== 'Vouchers') return true
				return UserLoginStore.permiteRegraDeNegocio('pode-ler-voucher-do-cliente')
			})
			.filter(aba => {
				if (aba !== 'Vendas') return true
				return UserLoginStore.permiteRegraDeNegocio('exibir-vendas-no-cadastro-do-cliente')
			})
			.filter(aba => {
				if (aba !== 'Grupos Econômicos') return true
				return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
			})
			.filter(aba => {
				if (aba !== 'Lojas') return true
				return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
			})
			.filter(aba => {
				if (aba !== 'Bloqueios de Pagamento') return true
				return UserLoginStore.permiteRegraDeNegocio('pode-visualizar-bloqueios-de-cliente')
			})
	}

	get podeAlterarCliente() {
		return this.podeRealizarOperacao('alterar')
	}

	get podeEditarNomeCpfCliente() {
		return UserLoginStore.permiteRegraDeNegocio('cadastro-nome-cpf-cliente')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'clientes' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'clientes'))
	}

	$refs!: {
		[key: string]: HTMLFormElement | CampoDeTelefone
	}

	mostrar(cliente: FormCliente, criandoNovoCliente: boolean) {
		this.cliente = JSON.parse(JSON.stringify({
			...cliente,
			dataDePagamentoDoPlano: cliente.dataDePagamentoDoPlano === undefined ? null : cliente.dataDePagamentoDoPlano,
			dataDeExpiracaoDoPlano: cliente.dataDeExpiracaoDoPlano === undefined ? null : cliente.dataDeExpiracaoDoPlano,
		}))
		this.mostra = true
		this.autoCompletaEndereco = true
		this.criandoCliente = criandoNovoCliente
		this.vendedor

		setTimeout(() => {
			this.form.resetValidation()
			this.campoDeNome.focus()
		})
	}

	esconder() {
		this.mostra = false
	}

	adicionarTelefone() {
		if (!this.cliente) return

		this.cliente.telefones.push('')
		this.$nextTick(() => {
			if (!this.cliente) return
			const campo = this.$refs[`telefone-${this.cliente.telefones.length - 1}`]
			campo[0].$children[0].focus()
		})
	}

	validarFormularios() {
		for (let i = 0; i < this.abasPermitidas.length; ++i) {
			const campo = this.$refs[`form${i}`]
			if ('validate' in campo && campo.validate()) continue
			this.tab = i
			return false
		}
		return true
	}

	async salvar() {
		await nextTick()
		if (!this.cliente) return
		if (!this.validarFormularios()) return
		try {
			this.salvando = true

			const cliente = {
				...this.cliente,
				cnpjOuCpf: this.cliente.cnpjOuCpf.replace(/\D/g, ''),
			}

			if (!cliente.tipoDeCliente) {
				cliente.dataDePagamentoDoPlano = null
				cliente.dataDeExpiracaoDoPlano = null
			}

			if (cliente.inscricaoEstadual.toUpperCase() === 'ISENTO') {
				cliente.inscricaoEstadual = cliente.inscricaoEstadual.toUpperCase()
			}

			if(this.vendedor) this.vendedor

			const clienteSalvo = this.cliente.id
				? await this.saveClienteUseCase.update(cliente)
				: await this.saveClienteUseCase.create(cliente)

			this.$emit('confirmado', clienteSalvo)
			this.$emit('clienteAtualizado')
			this.mostra = false
			AlertModule.setSuccess('Cliente salvo com sucesso')
			if(!this.cliente.id && this.preencherFormulario){
				this.mostrar(clienteSalvo, false)
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.nomeDeTratamentoModificado = false
		}
	}

	async onCnpjOuCpf() {
		if (!this.cliente?.cnpjOuCpf) return
		const cnpjOuCpf = this.cliente.cnpjOuCpf
		if (!cnpjOuCpf || !cnpjCpfValido(cnpjOuCpf)) return
		try {
			this.buscandoCnpjOuCpf = true
			const params = { 
				busca: cnpjOuCpf.replace(/\D/g, ''),
				gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id),
			}
			const data = await this.findClienteUseCase.find(params)
			if (!data.content.length) return
			const cliente = data.content[0]
			this.autoCompletaEndereco = false
			this.cliente.id = cliente.id
			this.cliente.razaoSocialOuNome = cliente.razaoSocialOuNome
			this.cliente.email = cliente.email
			this.cliente.tipoDeCliente = cliente.tipoDeCliente
			this.cliente.inscricaoSuframa = cliente.inscricaoSuframa
			this.cliente.inscricaoEstadual = cliente.inscricaoEstadual
			this.cliente.telefones = cliente.telefones
			this.cliente.dataNascimento = cliente.dataNascimento
			this.cliente.genero = cliente.genero
			this.cliente.profissao = cliente.profissao
			this.cliente.nomeDeTratamento = cliente.nomeDeTratamento		
			
			this.cliente.endereco = JSON.parse(JSON.stringify(cliente.endereco))
			this.cliente.idEstrangeiro = cliente.idEstrangeiro						
		} catch (e: any) {
			this.errors.push(e.message)
		} finally {
			this.buscandoCnpjOuCpf = false
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if(mostra) return
		this.cliente = null
	}
	
	async salvarEReabrir(){
		this.preencherFormulario = true
		this.salvar()
	}	

	abrirDialogoDeCriarEndereco() {
		this.dialogoDeEnderecoAdicional.mostrar({
			id: uuidv4(),
			endereco: {
				id: uuidv4(),
				rua: '',
				numero: null,
				cep: '',
				bairro: '',
				complemento: '',
				cidade: '',
				uf: 'SC',
				codigoIBGE: '',
				codigoPais: '',
				nomePais: '',
			} as Endereco,
			tipo: 'ENTREGA',
			isPadrao: false,
		} as EnderecoAdicional)
	}

	abrirDialogoDeEditarEndereco(indice: number) {
		if (!this.cliente) return
		
		const enderecoAdicional = this.cliente.enderecosAdicionais[indice]
		this.dialogoDeEnderecoAdicional.mostrar(enderecoAdicional)
	}

	atualizarEnderecos(enderecoAdicional: EnderecoAdicional) {
		if (!this.cliente) return
		if (!this.cliente.enderecosAdicionais) this.cliente.enderecosAdicionais = []

		const indice = this.cliente.enderecosAdicionais.findIndex(endereco => endereco.id === enderecoAdicional.id)
		if (indice >= 0) {
			this.cliente.enderecosAdicionais[indice] = enderecoAdicional
		} else {
			this.cliente.enderecosAdicionais.push(enderecoAdicional)
		}

		AppStore.sincronizar()
	}

	atualizarEnderecosComoPadrao(enderecoAdicional: EnderecoAdicional) {
		if (!this.cliente) return
		if (!this.cliente.enderecosAdicionais) this.cliente.enderecosAdicionais = []

		this.cliente.enderecosAdicionais.forEach(endereco => endereco.isPadrao = false)
		enderecoAdicional.isPadrao = true

		const indice = this.cliente.enderecosAdicionais.findIndex(endereco => endereco.id === enderecoAdicional.id)
		if (indice >= 0) {
			this.cliente.enderecosAdicionais[indice] = enderecoAdicional
		} else {
			this.cliente.enderecosAdicionais.push(enderecoAdicional)
		}

		AppStore.sincronizar()
	}

	handleKeyUp() {
		if (!this.nomeDeTratamentoModificado && this.cliente) {
			const primeiroNome = this.cliente.razaoSocialOuNome.split(' ')[0];
			this.cliente.nomeDeTratamento = primeiroNome;
		}
	}
}
