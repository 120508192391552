var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Selecione uma Modalidade","items":['Presencial', 'Link', 'Demonstração', 'Devolução', 'Ecommerce', 'Outro'],"multiple":"","clearable":""},model:{value:(_vm.filtro.origem),callback:function ($$v) {_vm.$set(_vm.filtro, "origem", $$v)},expression:"filtro.origem"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.buscando,"disabled":_vm.buscando},on:{"click":_vm.buscar}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" buscar ")],1)],1)],1),_c('GridDeVendaResumido',{attrs:{"vendas":_vm.vendas,"loading":_vm.buscando,"footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] },"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaVenda',{attrs:{"venda":item},on:{"buscar":_vm.buscar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes da venda ")])]}}],null,true)})],1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('strong',[_vm._v(" Valor Total de Vendas: "+_vm._s(_vm.somaTotalDasVendas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])])])])]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.itensFormatados.length,"loading":_vm.buscando},on:{"update:paginacao":function($event){_vm.paginacao=$event},"navegar":_vm.navegar}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }