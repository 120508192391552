

















































import { Vue, Component, Ref } from 'vue-property-decorator'
import CampoDeCnpjOuCpf from '@/components/ui/CampoDeCnpjOuCpf.vue'
import { cnpjCpfInvalido } from '@/shareds/regras-de-form'

@Component({
	components: {
		CampoDeCnpjOuCpf,
	},
})
export default class DialogoDeCpfDoCliente extends Vue {
	@Ref() formDialogoDeCpfDoCliente!: HTMLFormElement
	mostra: boolean | null = null
	resolveDialogo: ((value: string) => void) | null = null

	cnpjCpfInvalido = cnpjCpfInvalido
	exibirCpfNaNota = false
	cnpjCpfDoCliente = ''

	async mostrar(): Promise<string> {
		this.mostra = true
		return new Promise(resolve => this.resolveDialogo = resolve)
	}

	fechar() {
		this.resolveDialogo && this.resolveDialogo('')
		this.mostra = false
	}

	confirmar() {
		if (!this.formDialogoDeCpfDoCliente.validate()) return
		this.resolveDialogo && this.resolveDialogo(this.cnpjCpfDoCliente)
		this.mostra = false
	}
}

