












































import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import { Venda } from '@/models';
import { CartaDeCorrecao } from '@/models/fiscal/CartaDeCorrecao';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { CartaDeCorrecaoUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Component, Ref, Vue, Watch} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import DialogoDeCriacaoDaCartaDeCorrecao from './DialogoDeCriacaoDaCartaDeCorrecao.vue';

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeCriacaoDaCartaDeCorrecao,
	},
})
export default class ListagemDeCartasDeCorrecao extends Vue {
	@Ref() dialogoDeCriacaoDaCartaDeCorrecao!: DialogoDeCriacaoDaCartaDeCorrecao
	
	
	cancelToken: CancelTokenSource | null = null
	venda: Venda | null = null
	cartas: CartaDeCorrecao[] = []
	chNFe = ''
	totalRegistros = -1
	mostra = false
	loading = false

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	cartaDeCorrecaoUseCase = new CartaDeCorrecaoUseCase()

	header = [
		{ text: "Sequência", value: "sequencial" },
		{ text: "Status", value: "statusEMotivo" },
		{ text: "Correção", value: "descricaoDaCorrecao" },
	]

	async mostrar(venda: Venda | null = null) {
		this.venda = venda
		const notaFiltrada = venda?.notas.find(nota => nota.cstat === '100') || null
		if(!notaFiltrada) throw new Error('Nota válida não encontrada')
		this.chNFe = notaFiltrada.chNFe
		this.mostra = true
		this.buscarCartas()
	}

	@Watch('paginacao', { deep: true })
	async buscarCartas() {
		if(!this.mostrar) return 
		this.loading = true
		try {
			this.cancelToken = axios.CancelToken.source()

			const params = {
				chNFe: this.chNFe || undefined,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				sort: 'sequencial,desc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const cartas = await this.cartaDeCorrecaoUseCase.findCartasDeCorrecao(params, axiosConfig)
			this.cartas = cartas.content
			this.totalRegistros = cartas.totalElements
			this.loading = false
		} catch(error) {
			AlertModule.setError(error)
			this.loading = false
		}
	}

	mostrarCriacaoDaCartaDeCorrecao() {
		this.dialogoDeCriacaoDaCartaDeCorrecao.mostrar(this.venda)
	}

	get cartasFormatadas() {
		return this.cartas.map(carta => {
			return {
				...carta,
				statusEMotivo: carta.status + ' - ' + carta.motivoDeRetornoEvento,
			}
		})
	}

} 
