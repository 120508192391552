




























































import { Vue, Component, Prop, Watch, Ref, Emit } from 'vue-property-decorator'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import { Cliente, Fornecedor, Pessoa, Usuario, Venda } from '@/models'
import { Inject } from 'inversify-props'
import { extrairCnpjOuCpfDoCliente } from '@/shareds/cliente-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase, FindFornecedorUseCase } from '@/usecases'
import type { VendaServiceAdapter, ClienteServiceAdapter } from '@/usecases'
import { validarCpfOuCnpj } from '@/shareds/utils'
import SeletorDeFornecedor from '../fornecedor/SeletorDeFornecedor.vue'

@Component({
	components: {
		SeletorDePessoa,
		SeletorDeFornecedor,
	},
})
export default class DialogoDeEdicaoDeClienteDaVenda extends Vue {
	@Ref() campoDeCliente!: HTMLInputElement
	@Ref() campoDeFornecedor!: HTMLInputElement
	@Prop({ type: String }) idDaVenda?: string 
	@Prop({ type: String }) cnpjOuCpfInicial!: string | null
	@Prop({ type: Object }) representanteDaVenda!: Usuario | null
	@Prop({ type: Boolean }) selecionaFornecedor!: boolean

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	mostra: boolean | null = null

	editarVendaUseCase = new EditarUmaVendaUseCase()
	findFornecedorUseCase = new FindFornecedorUseCase()

	cliente: Cliente | string | null = null
	salvando = false
	carregando = false

	fornecedor: Fornecedor | null = null

	@Watch('mostra')
	async onChangeMostra(mostra: boolean) {
		if (!mostra) return

		if (!this.selecionaFornecedor) {
			if (!this.cnpjOuCpfInicial) {
				this.cliente = null
				return
			}
			this.cliente = this.cnpjOuCpfInicial
			window.requestAnimationFrame(() => {
				this.campoDeCliente.focus()
			})
		} else {
			if (!this.cnpjOuCpfInicial) {
				this.fornecedor = null
				return
			}

			this.fornecedor = await this.findFornecedorUseCase.get(this.cnpjOuCpfInicial)
		}
	}

	@Emit('update:cliente')
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	inputCliente(cliente: Cliente | null){
		// do nothing.
	}

	@Emit('update:cpfDoCliente')
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	inputCnpjOuCpf(cliente: string | null){
		// do nothing.
	}

	@Emit('update:representante')
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	inputRepresentante(venda: Venda | null){
		// do nothing.
	}

	async getCliente(cpfOuCnpj: string | null) {
		if (cpfOuCnpj) {
			try {
				return await this.clienteService.get(cpfOuCnpj);
			} catch(err) {
				return cpfOuCnpj
			}
		}
		return cpfOuCnpj
	}

	async confirmar() {
		let cnpjOuCpf
		let clienteDaVenda
		let produtosVerificados
		if (!this.selecionaFornecedor) {
			cnpjOuCpf = extrairCnpjOuCpfDoCliente(this.cliente)
			clienteDaVenda = this.cliente as Cliente
		} else {
			cnpjOuCpf = this.fornecedor ? this.fornecedor.cnpjOuCpf : this.cnpjOuCpfInicial
			clienteDaVenda = await this.getCliente(cnpjOuCpf) as Cliente
		}

		if(!validarCpfOuCnpj(cnpjOuCpf)) {
			AlertModule.setError('CPF ou CNPJ inválido')
			return
		}
		
		if (this.idDaVenda) {
			try {
				this.salvando = true
				await this.vendaService.update(this.idDaVenda, {
					cpfDoCliente: cnpjOuCpf,
					produtosVerificados: produtosVerificados,
				})

				if (clienteDaVenda && clienteDaVenda.representanteComercial && clienteDaVenda.representanteComercial !== null 
				&& this.representanteDaVenda && this.representanteDaVenda !== null) {
					const clienteJaPossuiEsseRepresentante = clienteDaVenda.representanteComercial.id === this.representanteDaVenda.id
					if(!clienteJaPossuiEsseRepresentante) {
						const venda = await this.editarVendaUseCase.patchRepresentante(this.idDaVenda, clienteDaVenda.representanteComercial)
						this.inputRepresentante(typeof venda === 'object'
							? venda
							: null,
						)
					}
				}
			} catch (error: any) {
				AlertModule.setError(error)
				throw error
			} finally {
				this.salvando = false
			}
		}
		this.inputCliente(typeof this.cliente === 'object'
			? this.cliente
			: null,
		)
		this.inputCnpjOuCpf(cnpjOuCpf)
		this.mostra = false
	}

	async clienteSelecionadoitem(item: Pessoa | string) {
		this.cliente = typeof item === 'string' ? await this.getCliente(item): await this.getCliente(item.cnpjOuCpf) 
	}
}

