



































import { formatDate, parseDate } from '@/shareds/date/date-utils'
import { Vue, Component, Ref, PropSync, Watch } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
	@PropSync('value', {
		default: new Date().toISOString().substr(0, 10),
	})
	date!: string | null
	@Ref() menu!: { save: (value: any) => void }

	dataFormatada: string | null = null

	mostrar = false

	parseDate = parseDate

	@Watch('date', { immediate: true })
	onChangeDate() {
		this.dataFormatada = formatDate(this.date)
	}
}
